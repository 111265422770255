import React from 'react';

import { useTranslation } from 'react-i18next';

import AuthHeader from '@nm-namshi-frontend/core/auth/components/AuthHeader';
import OtpForm from '@nm-namshi-frontend/core/auth/forms/OtpForm';
import useOtpAuth from '@nm-namshi-frontend/core/auth/hooks/useOtpAuth';
import PasswordForm from '@nm-namshi-frontend/core/auth/forms/PasswordForm';
import usePasswordAuth from '@nm-namshi-frontend/core/auth/hooks/usePasswordAuth';
import { TAuthMethod, TFlowProps } from '@nm-namshi-frontend/core/auth/types';
import DualTab from '@nm-namshi-frontend/core/auth/components/DualTab';
import useResetPassword from '@nm-namshi-frontend/core/auth/hooks/useResetPassword';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

const LoginFlow: React.FC<TFlowProps & { initialMethod: TAuthMethod }> = (props) => {
    const { email, initialMethod, onSuccess } = props;

    const { t } = useTranslation();

    const {
        doResendOtp,
        doSendOtp,
        doVerifyOtp,
        isLoading: isOtpLoading,
        isOtpSent,
        otpConfig,
    } = useOtpAuth({ onSuccess });
    const { doSubmitPassword, isLoading: isPasswordLoading } = usePasswordAuth({ onSuccess });

    const [method, setMethod] = React.useState<TAuthMethod>(initialMethod);

    const { doResetPassword, isLoading: isResetPasswordLoading } = useResetPassword();

    React.useEffect(() => {
        if (method === 'OTP' && !isOtpSent) {
            doSendOtp({ email });
        }
    }, [method]);

    const isLoading = isOtpLoading || isPasswordLoading;
    const authMessage =
        method === 'OTP' ? t('auth.login.otp-details', { email }) : t('auth.login.password-details', { email });

    const tabs = React.useMemo<{ id: TAuthMethod; label: string }[]>(
        () => [
            {
                id: 'OTP',
                label: t('auth.login.with-otp'),
            },
            {
                id: 'PASSWORD',
                label: t('auth.login.with-password'),
            },
        ],
        [t],
    );

    return (
        <>
            <AuthHeader title={t('auth.login.title')} />
            <DualTab<TAuthMethod> activeTab={method} onChange={setMethod} tabs={tabs} disabled={isLoading} />
            <AuthHeader details={authMessage} />

            {method === 'OTP' ? (
                <OtpForm
                    onResend={() => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'login_noon',
                            popupAction: 'resend_otp',
                        });
                        doResendOtp();
                    }}
                    onSubmit={(args) => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'login_noon',
                            popupAction: 'login',
                        });
                        doVerifyOtp(args);
                    }}
                    otpConfig={otpConfig}
                    isLoading={isLoading}
                    ctaText={t('auth.login.title')}
                />
            ) : (
                <PasswordForm
                    onSubmit={({ password }) => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'login_noon',
                            popupAction: 'login',
                        });

                        doSubmitPassword({ email, password });
                    }}
                    isLoading={isLoading || isResetPasswordLoading}
                    ctaText={t('auth.login.title')}
                    onResetPassword={({ email: _email }) => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'reset_password_noon',
                            popupAction: 'reset_password',
                        });

                        doResetPassword({ email: _email });
                    }}
                    resetPasswordEmail={email}
                />
            )}
        </>
    );
};

export default LoginFlow;
