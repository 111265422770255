// This is a single store for all things validation schema

import * as Yup from 'yup';
import valid from 'card-validator';
import { TFunction } from 'i18next';

import { getCCType } from './payments';
import madaData from './mada_banks.json';

enum SchemaKeys {
    'creditCardNumber',
    'cvv',
    'fullName',
    'firstName',
    'fullAddress',
    'email',
    'expiryMonth',
    'expiryYear',
    'isCardRemembered',
    'lastName',
    'password',
    'passwordNew',
    'passwordNewRepeat',
    'phone',
    'otp',
    'dob',
    'preferredLang',
    'nationality',
}

export type TSchemaKeys = keyof typeof SchemaKeys;

const MINIMUM_PASSWORD_LENGTH = 8;

const madaBins = madaData.map((bank) => bank.bin);
const madaLengths = [...Array.from(new Set(madaData.map((bank) => bank.pan_length)))];

valid.creditCardType.addCard({
    niceType: 'mada',
    type: 'mada',
    patterns: madaBins,
    gaps: [4, 8, 12],
    lengths: madaLengths,
    code: {
        name: 'CVV',
        size: 3,
    },
});

/**
 * @param  {Array<TSchemaKeys>} keys : Required schema keys
 * @param  {TFunction} t : Translation function for validation messages
 * @returns Validation schemas of only the keys that are sent
 */
export const getValidationSchema = (
    keys: Array<TSchemaKeys>,
    t: TFunction,
    errorOverrides?: Record<Partial<TSchemaKeys>, boolean>,
): Yup.AnyObjectSchema => {
    const SCHEMAS = {
        email: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.email-label') }))
            .email(t('validation._valid', { fieldName: t('authentication.email-label') })),
        password: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.password-label') }))
            .min(
                MINIMUM_PASSWORD_LENGTH,
                t('validation._minimum-length', {
                    fieldName: t('authentication.password-label'),
                    length: MINIMUM_PASSWORD_LENGTH,
                }),
            ),
        passwordNew: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.password-label') }))
            .min(
                MINIMUM_PASSWORD_LENGTH,
                t('validation._minimum-length', {
                    fieldName: t('authentication.password-label'),
                    length: MINIMUM_PASSWORD_LENGTH,
                }),
            ),
        passwordNewRepeat: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.password-label') }))
            .min(
                MINIMUM_PASSWORD_LENGTH,
                t('validation._minimum-length', {
                    fieldName: t('authentication.password-label'),
                    length: MINIMUM_PASSWORD_LENGTH,
                }),
            ),
        fullName: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.first-name-label') }))
            .matches(/^[a-zA-Z\u0600-\u06FF]{1}/, {
                message: t('validation._valid', { fieldName: t('authentication.first-name-label') }),
            }),
        firstName: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.first-name-label') }))
            .matches(/^[a-zA-Z\u0600-\u06FF]{1}/, {
                message: t('validation._valid', { fieldName: t('authentication.first-name-label') }),
            }),
        lastName: Yup.string()
            .required(t('validation._required', { fieldName: t('authentication.last-name-label') }))
            .matches(/^[a-zA-Z\u0600-\u06FF]{1}/, {
                message: t('validation._valid', { fieldName: t('authentication.last-name-label') }),
            }),
        phone: Yup.string()
            .required(t('validation._required', { fieldName: t('addresses.mobile-number-label') }))
            .test('valid-phone', t('validation._valid', { fieldName: t('addresses.mobile-number-label') }), () =>
                Boolean(errorOverrides?.phone),
            ), // Since PhoneInput performs validation, we use it as an override
        preferredLang: Yup.string().oneOf(['en', 'ar']).required(t('validation._required')),
        nationality: Yup.object({
            countryCode: Yup.string(),
            name: Yup.string(),
        }).required(t('validation._required')),

        dob: Yup.date().max(new Date(), t('profile._validation-dob-future')),

        fullAddress: Yup.string().required(t('validation._required', { fieldName: t('addresses.full-address-label') })),
        creditCardNumber: Yup.string()
            .required(t('validation._required', { fieldName: t('payments.card-number-label') }))
            .test(
                'valid-credit-card-number',
                t('validation._valid', { fieldName: t('payments.card-number-label') }),
                (value) => valid.number(value).isValid,
            ),
        expiryMonth: Yup.string()
            .required(t('validation._required', { fieldName: t('payments.card-expiry-date-text') }))
            .test(
                'valid-credit-card-expiry',
                t('validation._valid', { fieldName: t('payments.card-expiry-date-text') }),
                (value) => valid.expirationMonth(value).isValid,
            ),
        expiryYear: Yup.string()
            .required(t('validation._required', { fieldName: t('payments.card-expiry-date-text') }))
            .test(
                'valid-credit-card-expiry',
                t('validation._valid', { fieldName: t('payments.card-expiry-date-text') }),
                (value) => valid.expirationYear(value).isValid,
            ),
        cvv: Yup.string()
            .required(t('validation._required', { fieldName: t('payments.cvv-label') }))
            .test(
                'valid-cvv',
                t('validation._valid', { fieldName: t('payments.cvv-label') }),
                (value, creditCardNumber) => {
                    // custom validation to check CVV digits against card type ie; AMEX => 4, VISA/MASTER => 3
                    const cardNumber = creditCardNumber.parent.creditCardNumber as string;
                    const cardType = getCCType(cardNumber);

                    const isValidCVVDigits =
                        !!cardType && cardType === 'AMEX' ? value?.length === 4 : value?.length === 3;

                    return isValidCVVDigits;
                },
            ),
        otp: Yup.string()
            .required(t('validation._required', { fieldName: t('otp-label') }))
            .matches(/^\d+$/, {
                message: t('otp-invalid'),
            }),
    };

    return Yup.object().shape(SCHEMAS).pick(keys);
};
