import { useState } from 'react';

import { toast } from 'react-toastify';
import clsx from 'clsx';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { useQuery } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';
import { ERRORVIEW } from '@nm-namshi-frontend/core/constants/uiConstants';
import { ProfileDepartmentCode } from '@nm-namshi-frontend/services/models/ProfileDepartmentCode';
import { DEPARTMENTS_CONFIG } from '@nm-namshi-frontend/core/config';

import SizeSelectionSkeleton from './components/SizeSelectionSkeleton';
import SizeSelectionDataList from './components/SizeSelectionDataList';
import SizeSelectionNavBar from './components/SizeSelectionNavBar';
import styles from './SizeSelectionJourney.module.scss';

const SizeSelectionJourney = ({
    containerClassName,
    onSuccess,
}: {
    onSuccess?: () => void;
    containerClassName?: string;
}) => {
    const { isMobile } = useAppContext();

    const {
        data: sizeInfoResp,
        isError: isUserProfileSizeError,
        isLoading: isUserProfileSizesLoading,
    } = useQuery(
        [REACT_QUERY_KEYS.GET_USER_SIZE_PROFILE_SIZES],
        () => getApiInstance().preferences.getSizePreferences({}),
        {
            onSuccess: (data) => {
                if (!data) onSuccess?.();
            },
            onError: (error: any) => {
                toast.error(error.body.error);
                onSuccess?.();
            },
        },
    );

    const {
        data: userProfileInfo,
        isError: isUserProfileInfoError,
        isLoading: isUserProfileInfoLoading,
    } = useQuery([REACT_QUERY_KEYS.GET_USER_SIZE_PROFILE_INFO], () => getApiInstance().preferences.getProfileInfo({}), {
        onError: (error: any) => {
            toast.error(error.body.error);
            onSuccess?.();
        },
    });

    DEPARTMENTS_CONFIG.sort((a, b) => {
        if (a.code === userProfileInfo?.primaryCategory) return -1;
        if (b.code === userProfileInfo?.primaryCategory) return 1;
        return 0;
    });

    const [selectedGender, setSelectedGender] = useState<ProfileDepartmentCode>(
        DEPARTMENTS_CONFIG[0].code as ProfileDepartmentCode,
    );

    if (isUserProfileSizesLoading || isUserProfileInfoLoading) return <SizeSelectionSkeleton isMobile={isMobile} />;
    if (isUserProfileInfoError || isUserProfileSizeError) return <ErrorView type={ERRORVIEW.GENERIC_ERROR} />;

    return (
        <div
            className={clsx(containerClassName, {
                [styles.desktopContainer]: !isMobile,
            })}
        >
            <SizeSelectionNavBar
                setSelectedGender={setSelectedGender}
                selectedGender={selectedGender}
                primaryCategory={userProfileInfo?.primaryCategory}
            />
            <SizeSelectionDataList
                sizeDataResponse={sizeInfoResp}
                selectedGender={selectedGender}
                onSuccess={onSuccess}
                primaryCategory={userProfileInfo?.primaryCategory}
            />
        </div>
    );
};

export default SizeSelectionJourney;
