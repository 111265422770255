import forge from 'node-forge';
import { toast } from 'react-toastify';
import valid from 'card-validator';

import getImages from '@nm-namshi-frontend/core/utils/images';
import { ICONS } from '@nm-namshi-frontend/core/theme/icon';
import {
    TAMARA_INSTALLMENT_COUNT,
    TAMARA_LEARN_MORE_CDN_BASE_URL,
    TAMARA_PUBLIC_KEY,
    TAMARA_WIDGET_TYPE,
} from '@nm-namshi-frontend/core/config';

import { TCreditCardType, TLanguage, TRegionAPI } from '../types';
import { buildUrlWithQueryParams } from './url';

const { paymentMethods } = getImages();

export const tokenizeCC = async (key: string, sessionId: string, ccNumber: string): Promise<string> => {
    let numberEncrypted = '';
    try {
        const JSEncrypt = (await import('jsencrypt')).default;

        // eslint-disable-next-line new-cap
        const Encryption = new JSEncrypt();
        Encryption.setKey(key);
        numberEncrypted = Encryption.encrypt(`${sessionId}:${ccNumber}`) || '';
    } catch (err) {
        toast.error('Tokenization Failed');
        // @TODO : Ehab - Sentry integration
    }
    if (!numberEncrypted) {
        toast.error('Tokenization Failed');
        // @TODO : Ehab - Sentry integration
    }
    return numberEncrypted;
};

export const tokenizeCCv2 = (publicKey: string, details: string) => {
    const crypto = forge.pki.publicKeyFromPem(publicKey);
    const encryptedData = crypto.encrypt(details, 'RSA-OAEP', {
        md: forge.md.sha256.create(), // Use SHA-256 for OAEP
    });

    return forge.util.encode64(encryptedData);
};

export const truncateCardNumber = (cardNumber: string): string =>
    cardNumber?.slice(cardNumber?.length - 4) || cardNumber;

type TPaymentMethods = 'apple_pay' | 'cod' | 'cc_noonpay' | 'credits' | 'nopayment' | 'tabby' | 'tamara';
export const getPaymentMethodIcon = (method: TPaymentMethods): string => {
    switch (method?.toLowerCase()) {
        case 'apple_pay':
            return paymentMethods.applePay;
        case 'cod':
            return paymentMethods.cod;
        case 'credits':
        case 'nopayment':
            return ICONS.credits;
        case 'tabby':
            return paymentMethods.tabby;
        case 'tamara':
            return ICONS.Tamara;
        case 'cc_noonpay': // don't show icon as you'll show visa/mastercard etc instead
            return paymentMethods.card;
        default:
            return '';
    }
};

// attention cli - need to translate this?
export const getPaymentMethodLabel = (method: TPaymentMethods, t: any): string => {
    switch (method?.toLowerCase()) {
        case 'apple_pay':
            return t('orders.apple-pay');
        case 'cod':
            return t('orders.cash-on-delivery');
        case 'credits':
        case 'nopayment':
            return t('orders.credits');
        case 'tabby':
            return t('orders.tabby');
        case 'tamara':
            return t('orders.tamara');
        case 'cc_noonpay':
            return t('orders.debit-credit-card');
        default:
            return '';
    }
};

// type TCardTypes = 'AMEX' | 'MADA' | 'MASTERCARD' | 'VISA';
export const getCCTypeIcon = (cardType: string): string => {
    switch (cardType?.toLowerCase()) {
        case 'amex':
        case 'american express':
            return paymentMethods.amex;
        case 'mada':
            return paymentMethods.mada;
        case 'mastercard':
            return paymentMethods.mastercard;
        case 'visa':
            return paymentMethods.visa;
        default:
            return '';
    }
};

export const getCCType = (cardNumber: string): TCreditCardType | null => {
    const ccType = valid.number(cardNumber).card?.type;

    switch (ccType) {
        case 'american-express':
            return 'AMEX';
        case 'mastercard':
            return 'MASTERCARD';
        case 'visa':
            return 'VISA';
        case 'mada':
            return 'MADA';
        default:
            return null;
    }
};

export const getTamaraLearnMoreUrl = (
    lang: TLanguage = 'en',
    localAmount: number,
    currencyCode: string,
    countryCode: TRegionAPI,
) =>
    buildUrlWithQueryParams(
        TAMARA_LEARN_MORE_CDN_BASE_URL,
        [
            ['lang', lang],
            ['country', countryCode],
            ['widgetType', TAMARA_WIDGET_TYPE.INFORMATION_WIDGET],
            ['public_key', TAMARA_PUBLIC_KEY],
            ['amount', localAmount?.toString()],
            ['currency', currencyCode],
            ['paymentType', 'installment'],
            ['numberOfInstallments', `${TAMARA_INSTALLMENT_COUNT}`],
        ],
        false,
    );
// @TODO : Ehab - Revisit
// export const getPaymentMethodSEOPUrls = (methods: Array<TPaymentIcons>): Array<PaymentMethod> => {
//     const purlArray = methods
//         .map((method) => {
//             switch (method) {
//                 case 'cod':
//                     return 'http://purl.org/goodrelations/v1#COD';
//                 case 'visa':
//                     return 'http://purl.org/goodrelations/v1#VISA';
//                 case 'mastercard':
//                     return 'http://purl.org/goodrelations/v1#MasterCard';
//                 default:
//                     return 'http://purl.org/goodrelations/v1#COD';
//             }
//         })
//         .filter((method) => !!method);

//     if (!purlArray.length) return [];

//     return purlArray;
// };
