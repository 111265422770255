import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './VerifyEmailModal.module.scss';
import AuthHeader from '../components/AuthHeader';
import useOtpAuth from '../hooks/useOtpAuth';
import OtpForm from '../forms/OtpForm';
import EmailForm from '../forms/EmailForm';

type TProps = {
    isOpen: boolean;
    onSuccess: () => void;
    onClose: () => void;
};

const VerifyEmailModal: React.FC<TProps> = ({ isOpen, onClose, onSuccess }) => (
    <Modal
        open={isOpen}
        onClose={() => {
            trackEvent({ popupName: 'mig_verify_email', event: 'popup', popupAction: 'x' });
            onClose();
        }}
        desktopMaxWidth="500px"
        isContentCovering
    >
        <VerifyEmailForm onSuccess={onSuccess} />
    </Modal>
);

export const VerifyEmailForm: React.FC<{
    onSuccess: () => void;
}> = ({ onSuccess }) => {
    const { t } = useTranslation();

    const { doResendOtp, doSendOtp, doVerifyOtp, isLoading, otpConfig } = useOtpAuth({
        onSuccess,
        onOtpSent: () => setIsOtpStep(true),
    });

    const { data, isLoading: isGetLegacyCustomerLoading } = useQuery([REACT_QUERY_KEYS.GET_LEGACY_CUSTOMER], () =>
        getApiInstance().customerMigration.getLegacyCustomer({}),
    );

    const [email, setEmail] = React.useState('');
    const [isOtpStep, setIsOtpStep] = React.useState(false);

    return (
        <div className={styles.wrapper}>
            {isOtpStep ? (
                <div className={styles.container}>
                    <button
                        className={styles.backWrapper}
                        type="button"
                        onClick={() => {
                            trackEvent({
                                popupName: 'mig_verify_otp',
                                event: 'popup',
                                popupAction: 'back',
                            });

                            setIsOtpStep(false);
                        }}
                    >
                        <Icon name="arrowBack" size={24} />
                    </button>
                    <AuthHeader title={t('auth.verify.title')} details={t('auth.verify.details-otp', { email })} />
                    <OtpForm
                        onSubmit={({ otp }) => {
                            trackEvent({
                                popupName: 'mig_verify_otp',
                                event: 'popup',
                                popupAction: 'authenticate',
                            });

                            doVerifyOtp({ otp });
                        }}
                        isLoading={isLoading}
                        onResend={() => {
                            trackEvent({
                                popupName: 'mig_verify_otp',
                                event: 'popup',
                                popupAction: 'resend_otp',
                            });

                            doResendOtp();
                        }}
                        otpConfig={otpConfig}
                        ctaText={t('auth.verify.cta-otp')}
                    />
                </div>
            ) : (
                <div className={styles.container}>
                    <AuthHeader title={t('auth.verify.title')} details={t('auth.verify.details-email')} />
                    <EmailForm
                        disabled={isGetLegacyCustomerLoading}
                        onSubmit={({ email: _email }) => {
                            trackEvent({
                                popupName: 'mig_verify_email',
                                event: 'popup',
                                popupAction: 'verify your email',
                            });

                            setEmail(_email);
                            doSendOtp({ email: _email });
                        }}
                        isLoading={isLoading}
                        ctaText={t('auth.verify.cta-email')}
                        initialEmail={data?.email}
                    />
                </div>
            )}
        </div>
    );
};
export default VerifyEmailModal;
