import React, { useEffect, useRef, useState } from 'react';

import FieldLayout from '@nm-namshi-frontend/core/components/forms/FieldLayout';
import Select from '@nm-namshi-frontend/core/components/forms/Select';
import { PhoneCodesResponse } from '@nm-namshi-frontend/services/models/PhoneCodesResponse';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import styles from './PhoneInput.module.scss';
import TextInput from '../TextInput';
import CountryCodeSelect from './CountryCodeSelect';
import ModalSelect from '../ModalSelect';

type TProps = {
    disabled?: boolean;
    error?: string;
    helperText?: string;
    label?: string;
    name?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange: (e: string, isValid: boolean) => void;
    placeholder?: string;
    value: string;
    meta?: PhoneCodesResponse;
    defaultDialingCode: string;
};

const isLegacyDialCode = (dialCode?: string): boolean => !!dialCode && ['+971', '+966', '+20'].includes(dialCode);
const extractedPhoneDetails = (phone: string) => {
    let country = '';
    let area = '';
    let number = '';
    if (!phone) {
        return { country, area, number };
    }
    const [code] = phone ? phone.split('-') : [];
    if (isLegacyDialCode(code)) {
        [country, area, number] = phone.split('-') || ['', '', ''];
        return { country, area, number };
    }
    [country, number] = phone.split('-') || ['', ''];
    return { country, number };
};

const PhoneInput = ({
    defaultDialingCode,
    disabled = false,
    error,
    helperText,
    label,
    meta,
    name = '',
    onBlur,
    onChange,
    placeholder,
    value,
}: TProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>(null);

    const countryPhoneCodes = meta?.country_phone_codes || [];
    const { area, country, number } = extractedPhoneDetails(value);
    const internationalNumbersEnabled = meta?.is_country_intl_phones_enabled || false;
    const { isMobile } = useAppContext();

    const phoneData = {
        country: country || defaultDialingCode,
        area,
        number,
    };
    const [newValue, setNewValue] = useState(phoneData);

    // COUNTRY CODE
    const countryCodeOptions = countryPhoneCodes.map((_country) => ({
        areaCodes: _country.area_codes,
        label: `${_country.name} \u200E${_country.dial_code}`,
        nsn: _country.nsn,
        value: _country.dial_code,
    }));
    const changeCountryCode = (code?: string) => {
        setNewValue({ ...newValue, country: code || '', area: '', number: '' }); // RESET OTHER PARTS
    };
    const countryCodeSelected = countryCodeOptions.find((option) => option.value === newValue.country);

    // AREA CODE
    let areaCodeOptions = [{ label: newValue.area || '', value: newValue.area || '' }];
    if (countryCodeSelected?.areaCodes) {
        areaCodeOptions = countryCodeSelected?.areaCodes.map((code) => ({
            label: code.toString(),
            value: code.toString(),
        }));
    }

    const changeAreaCode = (code: string) => {
        setNewValue({ ...newValue, area: code || '' });
    };

    // REST OF PHONE NUMBER
    const areaCodeValueLength = areaCodeOptions?.[0]?.value?.toString()?.length || 0;
    const acceptedNumberLengths = countryCodeSelected?.nsn?.map((n) => +n - areaCodeValueLength) || [];
    const maxNumberLength = acceptedNumberLengths.length > 0 ? Math.max(...acceptedNumberLengths) : null;

    // 1. ONLY ALLOW NUMBERS OR CLEAR
    // 2. IF INPUT IS MORE THAN THE MAX LENGTH A NUMBER CAN BE, JUST CUT IT OFF
    const changeNumber = (_number: string) => {
        if (/^[\d ]+$/.test(_number) || _number === '') {
            let numberVal = _number;
            if (countryCodeSelected?.nsn && maxNumberLength) {
                if (_number.length >= maxNumberLength) {
                    numberVal = _number.slice(0, maxNumberLength);
                }
            }
            setNewValue({ ...newValue, number: numberVal });
        }
    };

    const handleClear = () => {
        setNewValue({ ...newValue, number: '' });
        if (ref.current) {
            ref.current.focus();
        }
    };

    // FORMAT ACCORDINGLY, CHECK IF VALID AND PASS TO FORM
    const formattedNumber =
        areaCodeOptions && areaCodeOptions.length > 0
            ? [newValue.country, newValue.area, newValue.number].join('-')
            : [newValue.country, newValue.number].join('-');

    const isNumberValid = () => {
        if (!newValue.country) return false;
        if (areaCodeOptions && areaCodeOptions.length > 0 && !newValue.area) return false;
        if (!acceptedNumberLengths.includes(newValue.number?.length)) return false;
        return true;
    };

    useEffect(() => {
        onChange(formattedNumber, isNumberValid());
    }, [newValue, meta]);

    return (
        <FieldLayout label={label} error={isNumberValid() ? '' : error} helperText={helperText}>
            <div className={styles.container}>
                <CountryCodeSelect
                    disabled={!internationalNumbersEnabled}
                    options={countryCodeOptions}
                    onChange={changeCountryCode}
                    selectedOption={countryCodeSelected}
                />
                {!!areaCodeOptions?.length && (
                    <div className={styles.areaCodeContainer}>
                        {isMobile ? (
                            <ModalSelect
                                onChange={changeAreaCode}
                                options={areaCodeOptions || []}
                                defaultValue={newValue.area || ''}
                                className={styles.modalSelect}
                            />
                        ) : (
                            <Select
                                id="area-code-select"
                                onChange={changeAreaCode}
                                options={areaCodeOptions || []}
                                placeholder="--"
                                rtlDisabled
                                value={newValue.area || ''}
                                menuPortalTarget={document.querySelector('body')}
                            />
                        )}
                    </div>
                )}
                <div className={styles.inputContainer}>
                    <TextInput
                        disabled={disabled}
                        name={name}
                        onBlur={onBlur}
                        onChange={(e) => changeNumber(e.target.value)}
                        placeholder={placeholder}
                        value={newValue.number}
                        onClear={handleClear}
                        inputMode="numeric"
                        isPhoneInput
                    />
                </div>
            </div>
        </FieldLayout>
    );
};

export default PhoneInput;
