import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import NoonXNamshiLogo from '@nm-namshi-frontend/core/components/NoonXNamshiLogo';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import useStartMigration from '@nm-namshi-frontend/core/migration/useStartMigration';
import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';

import styles from './AuthFlowModal.module.scss';
import FlowStart from './FlowStart';
import SignupFlow from './flows/SignupFlow';
import LoginFlow from './flows/LoginFlow';
import LegacySocialLoginButton from '../components/LegacySocialLoginButton';
import LegacyLoginFlow from './flows/LegacyLoginFlow';
import { TAuthMethod, TFlowCode, TFlowProps } from '../types';
import LearnMoreButton from '../components/LearnMoreButton';
import { VerifyEmailForm } from '../VerifyEmailModal/VerifyEmailModal';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    onSuccess?: () => void;
    enableSignupJourney?: boolean;
};

const Flows: Record<
    Exclude<TFlowCode, 'V2_SIGNUP'>,
    React.FC<TFlowProps> | React.FC<TFlowProps & { initialMethod: TAuthMethod }>
> = {
    V2_LOGIN: LoginFlow,
    V1_LOGIN: LegacyLoginFlow,
};

const AuthFlowModal: React.FC<TProps> = ({ enableSignupJourney = false, isOpen, onClose, onSuccess }) => (
    <Modal
        desktopWidth="max-content"
        desktopHeight="auto"
        desktopMaxHeight="90%"
        desktopMaxWidth="90%"
        mobileHeight="100%"
        open={isOpen}
        isContentCovering
    >
        <AuthFlowForm
            onCancel={onClose}
            onSuccess={() => {
                onClose();
                onSuccess?.();
            }}
            showClose
            enableSignupJourney={enableSignupJourney}
        />
    </Modal>
);

export const AuthFlowForm: React.FC<{
    onSuccess?: () => void;
    onCancel?: () => void;
    showClose?: boolean;
    enableSignupJourney?: boolean;
}> = ({onCancel, enableSignupJourney = false, onSuccess, showClose = false }) => {
    const { t } = useTranslation();
    const { isNotCleanUser, setIsNotClean } = useAuthenticationStore();
    const queryClient = useQueryClient();
    const fetchCustomerData = useCustomerStore((store) => store.fetchCustomerData);

    const [flow, setFlow] = React.useState<TFlowCode | null>(null);
    const [email, setEmail] = React.useState('');
    const [initialMethod, setInitialMethod] = React.useState<TAuthMethod>('OTP');

    const [isFormChangeDisabled, setIsFormChangeDisabled] = React.useState(false);
    const startMigration = useStartMigration();

    const handleClose = () => {
        if (isFormChangeDisabled) return;

        trackEvent({
            event: 'popup',
            popupName: 'get_access',
            popupAction: 'x',
        });
        onCancel?.();
    };

    const disableFormChange = () => {
        setIsFormChangeDisabled(true);
    };

    const handleSuccess = () => {
        fetchCustomerData();
        queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER]);
        queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_WISHLIST_ITEMS]);
        queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER_ADDRESSES]);
        queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_ACTIVE_SESSION]);

        queryClient.invalidateQueries([REACT_QUERY_KEYS.INIT_MIGRATION_FLOW]);
        onSuccess?.();
    };

    if (isNotCleanUser) {
        return (
            <VerifyEmailForm
                onSuccess={() => {
                    handleSuccess();
                    setIsNotClean(false);
                    startMigration();
                }}
            />
        );
    }

    const handleStartFlow = ({
        email: _email,
        flow: _flow,
        initial_tab,
    }: {
        flow: TFlowCode;
        initial_tab?: TAuthMethod;
        email: string;
    }) => {
        setFlow(_flow);
        setEmail(_email);

        if (initial_tab) {
            setInitialMethod(initial_tab);
        }
    };

    if (flow) {
        if (flow === 'V2_SIGNUP')
            return (
                <SignupFlow
                    email={email}
                    onSuccess={handleSuccess}
                    onBack={() => {
                        if (isFormChangeDisabled) return;
                        setFlow(null);
                    }}
                    onClose={handleClose}
                    isFormChangeDisabled={isFormChangeDisabled}
                    disableFormChange={disableFormChange}
                    isSignupJourneyEnabled={enableSignupJourney}
                />
            );

        const Flow = Flows[flow];

        return (
            <FlowWrapper
                onBack={() => {
                    if (isFormChangeDisabled) return;
                    setFlow(null);
                }}
                onClose={handleClose}
                isFormChangeDisabled={isFormChangeDisabled}
                showClose={showClose}
            >
                <Flow
                    email={email}
                    onSuccess={handleSuccess}
                    onBack={() => setFlow(null)}
                    initialMethod={initialMethod}
                    disableFormChange={disableFormChange}
                />
            </FlowWrapper>
        );
    }

    return (
        <>
            <FlowStart onSuccess={handleStartFlow} />
            {!isFormChangeDisabled && showClose && (
                <button type="button" className={styles.closeBtn} onClick={handleClose}>
                    <Icon name="close" size={25} />
                </button>
            )}

            <div className={styles.socialLoginWrapper}>
                <p className={styles.socialLoginDetails}>
                    {t('migration.nomore-social-login')}. {t('migration.social-login-to-migrate')}
                </p>
                <LegacySocialLoginButton onSuccess={handleSuccess} />
            </div>
        </>
    );
};

const FlowWrapper: React.FC<{
    children: React.ReactNode;
    onBack: () => void;
    onClose: () => void;
    isFormChangeDisabled: boolean;
    showClose: boolean;
}> = ({ children, isFormChangeDisabled = false, onBack, onClose, showClose }) => {
    const { t } = useTranslation();
    const { isMobile } = useAppContext();
    const { locale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);

    return (
        <div className={styles.flowWrapper}>
            {!isFormChangeDisabled && (
                <>
                    <button type="button" className={styles.backBtn} onClick={onBack}>
                        <Icon name="arrowBack" size={25} />
                    </button>
                    {showClose && (
                        <button type="button" className={styles.closeBtn} onClick={onClose}>
                            <Icon name="close" size={25} />
                        </button>
                    )}
                </>
            )}

            <div className={styles.learnMoreWrapper}>
                <LearnMoreButton ctaName={t('auth.init.learn-more')} popupName="get_access" />
            </div>
            <div className={styles.logoWrapper}>
                <NoonXNamshiLogo size={isMobile ? 50 : 70} lang={isArabic ? 'ar' : 'en'} />
            </div>

            {children}
        </div>
    );
};

export default AuthFlowModal;
