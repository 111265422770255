import { ApiError } from '@nm-namshi-frontend/services';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { TRegionAPI } from '@nm-namshi-frontend/core/types';

type TMigrate = (args: {
    countryCode: TRegionAPI;
    onError: (res: ApiError) => void;
    onSuccess: ({ migration_hash }: { migration_hash: string }) => void;
}) => void;

export const startMigration: TMigrate = async ({ countryCode, onError, onSuccess }) => {
    let customerDataResponse;

    // TODO: change, we will get noon_customer_code from backend on init
    try {
        customerDataResponse = await getApiInstance().customer.getCustomer({});
    } catch (err) {
        onError(err as ApiError);
        return;
    }

    const noon_customer_code = customerDataResponse.data.id;

    let migrationHashResponse;
    try {
        migrationHashResponse = await getApiInstance().customerMigration.getMigrationHash({
            requestBody: {
                noon_customer_code,
            },
        });
    } catch (err) {
        onError(err as ApiError);
        return;
    }

    const { migration_hash, namshi_customer_id } = migrationHashResponse;

    try {
        await getApiInstance().customerMigration.startMigration({
            requestBody: {
                updateCart: true,
                updateWishlist: true,
                updateWallet: true,
                noonCustomerCode: noon_customer_code,
                migration_hash,
                countryCode,
                namshiCustomerId: namshi_customer_id,
            },
        });
    } catch (err) {
        onError(err as ApiError);
        return;
    }

    onSuccess({ migration_hash });
};

export const trackMigrationProgress = async ({ migrationHash }: { migrationHash: string }) => {
    const trackMigrationHashResponse = await getApiInstance().customerMigration.getMigrationStatus({
        migrationHash,
    });

    return trackMigrationHashResponse;
};
