import React from 'react';

import styles from './AuthHeader.module.scss';

type TProps = {
    title?: string;
    details?: string;
};

const AuthHeader: React.FC<TProps> = ({ details, title }) => (
    <div className={styles.container}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {details && <p className={styles.details}>{details}</p>}
    </div>
);

export default AuthHeader;
