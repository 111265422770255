import { useTranslation } from 'react-i18next';

import { SignUpSteps } from '@nm-namshi-frontend/core/auth/AuthFlowModal/flows/SignupFlow';
import { isAccount } from '@nm-namshi-frontend/core/utils/helper';

import OnBoardingProfileJourney from '../OnBoardingProfileJourney';
import styles from './SignUpJourneySteps.module.scss';
import SizeSelectionJourney from '../SizeSelectionJourney';
import ProfileAllSet from '../ProfileAllSet';

const IS_ACCOUNT = isAccount();

type TProps = {
    step: SignUpSteps;
    setSignupJourneyStep: (newVal: SignUpSteps) => void;
    onSuccess: () => void;
};

const SignUpJourneySteps = ({ onSuccess, setSignupJourneyStep, step }: TProps) => {
    const { t } = useTranslation();

    const handleSuccessOrSkip = () => {
        if (!IS_ACCOUNT) {
            setSignupJourneyStep(SignUpSteps.ALLSET);
            return;
        }

        onSuccess?.();
    };

    switch (step) {
        case SignUpSteps.PROFILEINFO:
            return (
                <>
                    <div className={styles.titleContainer}>
                        <h2 className={styles.title}>{t('auth.signup.profileInfo-title')}</h2>
                    </div>
                    <OnBoardingProfileJourney setSignupJourneyStep={setSignupJourneyStep} onSuccess={onSuccess}/>
                </>
            );
        case SignUpSteps.SIZES:
            return (
                <>
                    <div className={styles.titleContainer}>
                        <h2 className={styles.title}>{t('auth.signup.my-size-title')}</h2>
                    </div>
                    <button className={styles.skipButton} type="button" onClick={handleSuccessOrSkip}>
                        {t('phone-verification.skip')}
                    </button>
                    <SizeSelectionJourney onSuccess={handleSuccessOrSkip} />
                </>
            );
        case SignUpSteps.ALLSET:
            return <ProfileAllSet onSuccess={onSuccess} />;
        default:
            return null;
    }
};

export default SignUpJourneySteps;
