import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './EmailOrPhoneOTPForm.module.scss';

type TProps = {
    header: {
        icon: string;
        title: string;
        subTitle: string;
        changeNumber?: boolean;
    };
    content: {
        message: string;
        emailOrPhone: string;
    };
    numDigits: number;
    code: string[];
    setCode: React.Dispatch<React.SetStateAction<string[]>>;
    onBack?: () => void;
    isDisabled?: boolean;
    isError?: boolean;
};

const EmailOrPhoneOTPForm = ({ code, content, header, isDisabled, isError, numDigits, onBack, setCode }: TProps) => {
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [isInvalid, setIsInvalid] = useState<boolean[]>(new Array(numDigits).fill(false));
    const { t } = useTranslation('account');

    useEffect(() => {
        setIsInvalid(new Array(numDigits).fill(isError));
    }, [isError]);

    const handleInput = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
            const target = e.target as HTMLInputElement;
            const { value } = target;

            // Handle backspace by focusing the previous input if empty
            if (value.length === 0) {
                setCode((prevCode) => {
                    const newCode = [...prevCode];
                    newCode[index] = '';
                    return newCode;
                });
                if (index > 0) inputRefs.current[index - 1]?.focus();
                setIsInvalid((prevInvalid) => {
                    const newInvalid = [...prevInvalid];
                    newInvalid[index] = false;
                    return newInvalid;
                });
                return;
            }

            if (!Number.isNaN(Number(value)) && Number(value) >= 0) {
                // Single digit input
                if (value.length === 1) {
                    const newCode = [...code];
                    newCode[index] = value;
                    setCode(newCode);
                    setIsInvalid((prevInvalid) => {
                        const newInvalid = [...prevInvalid];
                        newInvalid[index] = false;
                        return newInvalid;
                    });

                    // Move focus to the next input if it's not the last one
                    if (index < numDigits - 1) {
                        inputRefs.current[index + 1]?.focus();
                    }
                } else if (value.length > 1) {
                    if (code[index]) {
                        // if there is already a value in the input, user is replacing the value
                        const newCode = [...code];
                        // get what the user is trying to replace could be first or last digit, depending on cursor position
                        if (value.length === 2) {
                            const replaceValue = code[index] === value[0] ? value[1] : value[0];
                            newCode[index] = replaceValue;
                            setCode(newCode);
                            setIsInvalid((prevInvalid) => {
                                const newInvalid = [...prevInvalid];
                                newInvalid[index] = false;
                                return newInvalid;
                            });
                            target.value = replaceValue;
                            if (index < numDigits - 1) {
                                inputRefs.current[index + 1]?.focus();
                            }
                        } else {
                            // do not allow pasting
                            target.value = code[index];
                        }
                    } else {
                        const validDigits = value.slice(0, numDigits - index);
                        const newCode = [...code];

                        for (let i = 0; i < validDigits.length; i += 1) {
                            newCode[index + i] = validDigits[i];

                            const input = inputRefs.current[index + i];
                            if (input) {
                                input.value = validDigits[i];
                            }
                        }
                        // Move focus to the next input if it's not the last one
                        if (index + validDigits.length < numDigits - 1) {
                            inputRefs.current[index + validDigits.length]?.focus();
                        } else {
                            inputRefs.current[numDigits - 1]?.focus();
                        }
                        setCode(newCode);
                        setIsInvalid((prevInvalid) => {
                            const newInvalid = [...prevInvalid];
                            for (let i = 0; i < validDigits.length; i += 1) {
                                newInvalid[index + i] = false;
                            }
                            return newInvalid;
                        });
                    }
                }
            } else {
                // Handle non-numeric input
                const newCode = [...code];
                newCode[index] = '';
                e.target.value = '';
                setCode(newCode);
            }
        },
        [code, numDigits, setCode, inputRefs, setIsInvalid],
    );

    const renderDigitInput = (idx: number) => (
        <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            className={clsx(styles.digitContainer, { [styles.invalid]: isInvalid[idx] })}
            key={idx}
            ref={(el) => {
                inputRefs.current[idx] = el;
            }}
            onInput={(e) => handleInput(e as ChangeEvent<HTMLInputElement>, idx)}
            disabled={isDisabled}
        />
    );

    const renderHeader = () => (
        <div className={styles.header}>
            <Icon name={header.icon === 'email' ? 'email' : 'phone'} className={styles.headerIcon} size={30} />
            <div className={styles.headerContent}>
                <span className={styles.title}>{header.title}</span>
                <div className={styles.subHeadings}>
                    {header.subTitle && <span className={styles.subTitle}>{header.subTitle}</span>}
                    {header.changeNumber && (
                        <span className={styles.changeLink} onClick={onBack}>
                            {t('profile.change-number')}
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
    const renderContent = () => (
        <div className={styles.contentContainer}>
            <div className={styles.otpContent}>
                <span className={styles.otpMessage}>{content.message}</span>
                <span className={styles.otpContent}>{content.emailOrPhone}</span>
            </div>
            <div className={styles.digitsContainer}>
                {new Array(numDigits).fill(null).map((_, idx) => renderDigitInput(idx))}
            </div>
        </div>
    );

    return (
        <div className={clsx(styles.container, { [styles.disabled]: isDisabled })}>
            {renderHeader()}
            {renderContent()}
        </div>
    );
};

export default EmailOrPhoneOTPForm;
