import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import Button from '@nm-namshi-frontend/core/components/Button';
import { getValidationSchema } from '@nm-namshi-frontend/core/utils/forms';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';
import { TOtpConfig } from '@nm-namshi-frontend/core/auth/types';

import styles from './OtpForm.module.scss';

type TFormValues = {
    otp: string;
};

type TProps = {
    onSubmit: (values: TFormValues) => void;
    isLoading: boolean;
    onResend: () => void;
    otpConfig: TOtpConfig;
    ctaText: string;
    onSendOtpStep?: () => void;
    onVerifyOtpStep?: () => void;
};

const OtpForm: React.FC<TProps> = ({ ctaText, isLoading = false, onResend, onSubmit, otpConfig }) => {
    const { t } = useTranslation();
    const formSchemas = getValidationSchema(['otp'], t);

    const [isResendEnabled, setIsResendEnabled] = useState(false);

    const formInstance = useFormik<TFormValues>({
        initialValues: {
            otp: '',
        },
        validateOnMount: true,
        validationSchema: formSchemas,
        onSubmit,
    });

    const handleResend = () => {
        formInstance.setFieldValue('otp', '');
        onResend();
        setIsResendEnabled(false);
    };

    return (
        <form className={styles.form} onSubmit={formInstance.handleSubmit}>
            <div>
                <div className={styles.resendWrapper}>
                    {isResendEnabled ? (
                        <button type="button" onClick={handleResend}>
                            {t('migration.noon-auth.resend-otp-cta')}
                        </button>
                    ) : (
                        <ResendCountDown
                            onComplete={() => setIsResendEnabled(true)}
                            canResendAfter={otpConfig.coolDownTimer}
                        />
                    )}
                </div>

                <TextInput
                    inputClassName={styles.otpInput}
                    inputMode="numeric"
                    autofocus
                    error={
                        formInstance.errors.otp && formInstance.touched.otp && formInstance.dirty
                            ? formInstance.errors.otp
                            : undefined
                    }
                    label={t('migration.noon-auth.otp')}
                    name="otp"
                    onBlur={formInstance.handleBlur}
                    onChange={formInstance.handleChange}
                    onClear={() => formInstance.setFieldValue('otp', '', true)}
                    value={formInstance.values.otp}
                    maxLength={otpConfig.otpLength}
                />
            </div>

            <Button
                variant="tertiary"
                loading={isLoading}
                size="large"
                type="submit"
                customStyle={styles.btn}
                disabled={!formInstance.isValid}
            >
                {ctaText}
            </Button>
        </form>
    );
};

const ResendCountDown: React.FC<{ onComplete: () => void; canResendAfter: number }> = ({
    canResendAfter,
    onComplete,
}) => {
    const [timeRemaining, setTimeRemaining] = useState(canResendAfter || 30);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(tick, 1000);

        return () => clearInterval(interval);
    }, []);

    const tick = () => {
        setTimeRemaining((prev) => {
            if (prev <= 0) {
                onComplete();
                return 0;
            }

            return prev - 1;
        });
    };

    return <p>{t('migration.noon-auth.resend-otp-cta-after', { seconds: timeRemaining })}</p>;
};

export default OtpForm;
