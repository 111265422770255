import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { ApiError, ResetPasswordRequest } from '@nm-namshi-frontend/services';

const useResetPassword = () => {
    const { t } = useTranslation();

    const { isLoading, mutateAsync: doResetPassword } = useMutation(
        [REACT_QUERY_KEYS.RESET_PASSWORD],
        (resetParams: ResetPasswordRequest) =>
            getApiInstance().auth.resetPassword({
                requestBody: resetParams,
            }),
        {
            onSuccess: () => {
                toast.success(t('authentication.forgot-password-success-subtitle'));
            },
            onError: (error: ApiError) => {
                toast.error(error.body.error);
            },
        },
    );

    return {
        isLoading,
        doResetPassword,
    };
};

export default useResetPassword;
