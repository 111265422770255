import React from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { getValidationSchema } from '@nm-namshi-frontend/core/utils/forms';
import Button from '@nm-namshi-frontend/core/components/Button';
import PasswordInput from '@nm-namshi-frontend/core/components/forms/PasswordInput';
import ResetPasswordButton from '@nm-namshi-frontend/core/auth/components/ResetPasswordButton';

import styles from './PasswordForm.module.scss';

type TFormValue = {
    password: string;
};

type TProps = {
    onSubmit: (val: TFormValue) => void;
    isLoading: boolean;
    ctaText: string;
    onResetPassword: (val: { email: string }) => any;
    resetPasswordEmail?: string;
};

const PasswordForm: React.FC<TProps> = (props) => {
    const { ctaText, isLoading, onResetPassword, onSubmit, resetPasswordEmail } = props;

    const { t } = useTranslation();
    const formSchemas = getValidationSchema(['password'], t);

    const formInstance = useFormik<TFormValue>({
        initialValues: {
            password: '',
        },
        validateOnMount: true,
        validationSchema: formSchemas,
        onSubmit,
    });

    return (
        <form className={styles.form} onSubmit={formInstance.handleSubmit}>
            <PasswordInput
                autofocus
                error={
                    formInstance.errors.password && formInstance.touched.password && formInstance.dirty
                        ? formInstance.errors.password
                        : undefined
                }
                label={t('authentication.password-label')}
                name="password"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                value={formInstance.values.password}
                placeholder={t('authentication.password-label')}
            />
            <ResetPasswordButton onSubmit={onResetPassword} isLoading={isLoading} defaultEmail={resetPasswordEmail} />

            <Button
                variant="tertiary"
                customStyle={styles.btn}
                disabled={!(formInstance.isValid && formInstance.dirty)}
                loading={isLoading}
                type="submit"
                size="large"
            >
                {ctaText}
            </Button>
        </form>
    );
};

export default PasswordForm;
