import React, { useState } from 'react';

import Select, { components, StylesConfig, DropdownIndicatorProps } from 'react-select';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import type { TOption } from '@nm-namshi-frontend/core/types';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './CountryCodeSelect.module.scss';

type TProps = {
    disabled?: boolean;
    onChange: (code?: string) => void;
    options: TOption[];
    selectedOption?: TOption;
};

const DropdownIndicator = (props: DropdownIndicatorProps<any>) => (
    <components.DropdownIndicator {...props}>
        <Icon color="grey1" name="search" size={24} />
    </components.DropdownIndicator>
);

const CountryCodeSelect = ({ disabled, onChange, options, selectedOption }: TProps): JSX.Element => {
    const { t } = useTranslation('common');
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => setIsOpen((open) => !open);

    const NoOptionsMessage = (props: any) => (
        <components.NoOptionsMessage {...props}>{t('shared.no-results-found')}</components.NoOptionsMessage>
    );

    const onSelectChange = (option: any) => {
        toggleOpen();
        onChange(option?.value);
    };

    /* attention cli, this is nipun - the false right here    vvvvv  is for multiselect */
    /* not sure how to handle it best */
    /* should we keep it false? */
    const customStyles: StylesConfig<Record<string, unknown>, false> = {
        control: (provided) => ({ ...provided, minWidth: 240, margin: 8, borderColor: 'var(--color-grey3)' }),
        menu: (provided) => ({
            ...provided,
            boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid',
            borderRadius: 0,
            borderColor: 'var(--color-grey3)',
            zIndex: 10,
            marginLeft: '-1px',
            width: 'calc(100% + 2px)',
        }),
        option: (provided, state) => ({
            ...provided,
            padding: '8px',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            backgroundColor: state.isSelected ? 'var(--clr-dark)' : 'transparent',
            // eslint-disable-next-line no-nested-ternary
            color: state.isSelected ? 'var(--clr-white)' : state.isDisabled ? 'var(--color-grey3)' : 'inherit',
            '&:hover': {
                backgroundColor: state.isSelected ? 'var(--clr-dark)' : 'var(--color-system-blue-tint)',
            },
            pointerEvents: state.isDisabled ? 'none' : 'auto',
        }),
        menuPortal: (base) => ({ ...base, zIndex: 999 }),
    };

    return (
        <div className={clsx(styles.dropdown)}>
            <button className={clsx(styles.trigger, 'ltrForce')} disabled={disabled} onClick={toggleOpen} type="button">
                {selectedOption ? `${selectedOption.value}` : t('shared.select')}
                <Icon name="chevronDown" size={14} />
            </button>
            {isOpen && (
                <>
                    <div className={styles.menu}>
                        <Select
                            autoFocus
                            backspaceRemovesValue={false}
                            components={{ DropdownIndicator, IndicatorSeparator: null, NoOptionsMessage }}
                            controlShouldRenderValue={false}
                            hideSelectedOptions={false}
                            isClearable={false}
                            maxMenuHeight={160}
                            menuIsOpen
                            onChange={onSelectChange}
                            options={options}
                            placeholder={t('shared.search')}
                            styles={customStyles}
                            tabSelectsValue={false}
                            value={selectedOption}
                        />
                    </div>
                    <div className={styles.scrim} onClick={toggleOpen} aria-hidden="true" />
                </>
            )}
        </div>
    );
};

export default CountryCodeSelect;
