import React, { useEffect, useState } from 'react';

import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';

import getImages from '@nm-namshi-frontend/core/utils/images';

import styles from './ProfileAllSet.module.scss';

const LottieWrapper = dynamic(() => import('@nm-namshi-frontend/core/components/LottieWrapper'));

const ProfileAllSet = ({ onSuccess }: { onSuccess: () => void }) => {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation('common');

    useEffect(() => {
        const timer = setTimeout(() => {
            onSuccess();
            setIsVisible(false);
        }, 2000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <>
            <div className={styles.heightContainer} />
            <div className={styles.container}>
                <div className={styles.allSetAnimation}>
                    <LottieWrapper source={getImages().onBoardingJourney.profileAllSetAnimation} />
                </div>
                <h2 className={styles.allSetLabel}>{t('auth.signup.all-set')}</h2>
                <p className={styles.allSetDescription}>{t('auth.signup.all-set-description')}</p>
            </div>
        </>
    );
};

export default ProfileAllSet;
