import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { ApiError } from '@nm-namshi-frontend/services';

const useLegacyResetPassword = () => {
    const { t } = useTranslation();

    const { isLoading, mutateAsync: doLegacyResetPassword } = useMutation(
        [REACT_QUERY_KEYS.NAMSHI_RESET_PASSWORD],
        (payload: { email: string }) =>
            getApiInstance().namshiAuth.namshiResetPassword({ email: payload.email, requestBody: payload }),
        {
            onSuccess: () => {
                toast.success(t('authentication.forgot-password-success-subtitle'));
            },
            onError: (error: ApiError) => {
                toast.error(error.body.error);
            },
        },
    );

    return {
        isLoading,
        doLegacyResetPassword,
    };
};

export default useLegacyResetPassword;
