import { useEffect, useMemo, useRef } from 'react';

import _, { get, isUndefined, isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    ApiError,
    GetPaymentCardsResponse,
    PaymentMethods,
    SessionDetailsResponse,
} from '@nm-namshi-frontend/services/';
import { PaymentStatusResponse } from '@nm-namshi-frontend/services/models/PaymentStatusResponse';
import { reportEvent } from '@nm-namshi-frontend/core/utils/sentry';
import { tokenizeCCv2 } from '@nm-namshi-frontend/core/utils/payments';

import { getApiInstance } from '../api';
import { REACT_QUERY_KEYS } from '../constants/reactQueryKeys';
import useLayoutStore from '../stores/useLayoutStore';
import useCheckoutStore from '../stores/useCheckoutStore';
import { CheckoutButtonState, EPaymentIntentStatus, EPaymentMethodCodes } from '../types/payment';
import {
    TABBY_DEFAULT_PRODUCT,
    TABBY_INSTALLMENT_COUNT,
    TAMARA_DEFAULT_PRODUCT,
    TAMARA_INSTALLMENT_COUNT,
} from '../config';
import useAppPathname from './useAppPathname';
import { TCreditCardType } from '../types';
import { trackEvent } from '../utils/analytics';
import { ROUTES } from '../constants/uiConstants';

const {
    COMPLETE_PAYMENT_INTENT,
    GET_CUSTOMER_PAYMENT_CARDS,
    GET_ORDER_DETAILS,
    GET_PAYMENT_INTENT_STATUS,
    PLACE_ORDER,
} = REACT_QUERY_KEYS;

type TProps = {
    sessionData?: SessionDetailsResponse;
};

const usePerformCheckout = ({ sessionData }: TProps) => {
    const { pushWithLocaleAndPath, replaceWithLocaleAndPath } = useAppPathname();
    const { t } = useTranslation('common');
    const timeoutRef = useRef(0);

    // Creating these ref's to avoid enclosing scope issues within the setTimeouts
    const clientSecretRef = useRef('');
    const activeOrderNrRef = useRef('');

    const selectedAddressKey = get(sessionData, 'addressKey');
    const paymentMethodCode = get(sessionData, 'paymentDetails.paymentMethodCode', '');
    const paymentAmount = get(sessionData, 'paymentAmount');
    const totalAmount = get(sessionData, 'totalAmount');
    const idCustomerToken = get(sessionData, 'paymentDetails.paymentToken');
    const paymentProviderCode = get(sessionData, 'paymentProviderCode');

    const doItemsExistInSession = Boolean(sessionData?.items?.length);

    useEffect(
        () => () => {
            // Cleanups!
            clearTimeout(timeoutRef.current);
            clientSecretRef.current = '';
            activeOrderNrRef.current = '';
        },
        [],
    );

    const { toggleShowFullScreenLoader } = useLayoutStore();

    // Zustand-maintained
    const storeCvv = useCheckoutStore((s) => s.cvv);
    const setCvv = useCheckoutStore((s) => s.setCvv);

    const clearCvv = () => setCvv('');

    const cardType = sessionData?.paymentDetails.creditCardType as TCreditCardType;
    const numOfCVVDigits = storeCvv.length;
    const isValidCVV = cardType === 'AMEX' ? numOfCVVDigits === 4 : numOfCVVDigits === 3;
    const rqClient = useQueryClient();

    const paymentCardData = rqClient.getQueryData<GetPaymentCardsResponse>([GET_CUSTOMER_PAYMENT_CARDS]);

    const checkoutButtonState: CheckoutButtonState = useMemo(() => {
        if (!selectedAddressKey) {
            return CheckoutButtonState.SET_ADDRESS;
        }
        const isCvvRequired = get(sessionData, 'paymentDetails.isCvvRequired');

        switch (paymentMethodCode) {
            case EPaymentMethodCodes.Tabby:
                return CheckoutButtonState.TABBY;

            case EPaymentMethodCodes.Tamara:
                return CheckoutButtonState.TAMARA;

            case EPaymentMethodCodes.CashOnDelivery:
                return CheckoutButtonState.PLACE_ORDER;

            case '':
                return CheckoutButtonState.SELECT_METHOD;

            case EPaymentMethodCodes.NoonCredits:
                if (paymentAmount === 0) {
                    return CheckoutButtonState.PLACE_ORDER;
                }
                return CheckoutButtonState.SELECT_METHOD;

            case EPaymentMethodCodes.CreditCard:
                if (!!storeCvv && isValidCVV && isCvvRequired) {
                    return CheckoutButtonState.PLACE_ORDER;
                }
                if (!!paymentCardData && !isEmpty(paymentCardData.data)) {
                    return CheckoutButtonState.ENTER_CVV;
                }

                return CheckoutButtonState.ADD_NEW_CARD;

            default:
                return CheckoutButtonState.PLACE_ORDER;
        }
    }, [sessionData, paymentMethodCode, storeCvv, isValidCVV]);

    // Callbacks
    const checkPaymentIntentStatus = (data: PaymentStatusResponse) => {
        const { redirect_url, status } = data;

        switch (status) {
            case EPaymentIntentStatus.PaymentPending:
                clearTimeout(timeoutRef.current);
                timeoutRef.current = window.setTimeout(() => getPaymentIntentStatus(clientSecretRef.current), 3000);
                break;
            case EPaymentIntentStatus.PaymentReadyToRedirect:
                if (redirect_url) {
                    window.location.replace(redirect_url);
                }
                break;
            case EPaymentIntentStatus.PaymentConfirmed:
            case EPaymentIntentStatus.PaymentCaptured:
                toggleShowFullScreenLoader(false);
                replaceWithLocaleAndPath(`/${ROUTES.CHECKOUT}?order=${activeOrderNrRef.current}`);
                break;

            case EPaymentIntentStatus.PaymentCancelled:
            case EPaymentIntentStatus.PaymentRejected:
            case EPaymentIntentStatus.PaymentReversed:
            case EPaymentIntentStatus.PaymentRefunded:
            case EPaymentIntentStatus.PaymentExpired:
            case EPaymentIntentStatus.PaymentFailed:
            default:
                toggleShowFullScreenLoader(false);
                pushWithLocaleAndPath(`/${ROUTES.CHECKOUT}/?order=${data?.reference || ''}`);
                break;
        }
    };

    const getPaymentIntentRequestBody = async () => {
        const baseRequestBody = {
            clientSecret: clientSecretRef.current,
            paymentMethodCode: paymentMethodCode as PaymentMethods,
        };

        switch (paymentMethodCode) {
            case EPaymentMethodCodes.CreditCard:
                // eslint-disable-next-line no-case-declarations
                const { cvv_encryption } =
                    (await getApiInstance().checkout.getPaymentConfigConfigGet({
                        xMp: 'namshi_v2',
                    })) || {};
                if (!cvv_encryption) throw new Error();

                return {
                    ...baseRequestBody,
                    paymentProviderCode,
                    cvvEncryption: {
                        publicKeyName: cvv_encryption?.public_key_name,
                        encCvv: tokenizeCCv2(cvv_encryption.public_key, storeCvv),
                    },
                    idCustomerToken: Number(idCustomerToken),
                };
            case EPaymentMethodCodes.Tabby:
                return {
                    ...baseRequestBody,
                    paymentType: TABBY_DEFAULT_PRODUCT,
                    installments: TABBY_INSTALLMENT_COUNT,
                };
            case EPaymentMethodCodes.Tamara:
                return {
                    ...baseRequestBody,
                    paymentType: TAMARA_DEFAULT_PRODUCT,
                    installments: TAMARA_INSTALLMENT_COUNT,
                };
            default:
                return baseRequestBody;
        }
    };

    const getIsCheckoutEnabled = () => {
        const areBasicConditionsMet =
            !!selectedAddressKey && !isUndefined(totalAmount) && doItemsExistInSession && !!paymentMethodCode;
        const isPaymentAmountMet = !isUndefined(paymentAmount) && paymentAmount === 0; // This field is updated by BE in scenarios like noon credits being used for the order i.e it is 0 when credits satisfies the total order amount
        const isCvvRequired = get(sessionData, 'paymentDetails.isCvvRequired');

        if (paymentMethodCode === EPaymentMethodCodes.NoonCredits) {
            return areBasicConditionsMet && isPaymentAmountMet;
        }

        if (paymentMethodCode === EPaymentMethodCodes.CreditCard && isCvvRequired) {
            // This will handle partial payments ie. noon credits + credit card
            return areBasicConditionsMet && !!storeCvv && isValidCVV;
        }

        return areBasicConditionsMet;
    };

    const performCheckout = () => {
        if (getIsCheckoutEnabled() && totalAmount) {
            placeOrder(totalAmount);
        }
    };

    const { mutate: placeOrder } = useMutation(
        [PLACE_ORDER],
        (total: number) => {
            toggleShowFullScreenLoader(true);
            return getApiInstance().order.placeOrder({
                requestBody: {
                    total,
                },
                xAddresskey: selectedAddressKey,
                xPage: 'checkout',
            });
        },
        {
            onSuccess: (data) => {
                // tracking
                if (data && !isEmpty(sessionData)) {
                    const discountCodes: Array<string> =
                        sessionData.items?.map((item) => item?.autoapplyCode || '')?.filter((code) => !!code) || [];

                    trackEvent({
                        event: 'purchase',
                        orderNr: data.orderNr,
                        skuList:
                            sessionData.items?.map(({ isGlobal, isRocket, sku }) => ({
                                sku,
                                isRocket: isRocket ? 1 : 0,
                                isGlobal: isGlobal ? 1 : 0,
                            })) || [],
                        paymentMethod: paymentMethodCode || '',
                        hasCoupon: sessionData.couponCodes && sessionData.couponCodes.length > 0 ? 1 : 0,
                        couponList: sessionData.couponCodes || [],
                        discountCodes: _.uniq(discountCodes),
                    });
                }
                // @TODO Any more checks?
                if (data.orderNr) {
                    activeOrderNrRef.current = data.orderNr;
                    if (
                        paymentMethodCode === EPaymentMethodCodes.CashOnDelivery ||
                        paymentMethodCode === EPaymentMethodCodes.NoonCredits
                    ) {
                        toggleShowFullScreenLoader(false);
                        replaceWithLocaleAndPath(`/${ROUTES.CHECKOUT}?order=${activeOrderNrRef.current}`); // COD, noon credits does not require any payment intent creation
                        window.scroll(0, 0);
                    } else {
                        clearTimeout(timeoutRef.current);
                        timeoutRef.current = window.setTimeout(getOrderDetails, 3000); // 1. Set the order number that has just been placed
                    }
                }
            },
            onError: (error: ApiError) => {
                toast.error(error.body.error);
                toggleShowFullScreenLoader(false);
            },
        },
    );

    const { mutate: getOrderDetails } = useMutation(
        [GET_ORDER_DETAILS],
        () => {
            toggleShowFullScreenLoader(true);
            return getApiInstance().order.getOrderSummary({
                requestBody: {
                    orderNr: activeOrderNrRef.current,
                },
            });
        },
        {
            onSuccess: (data) => {
                const { paymentIntentToken: clientSecret } = data;

                if (!clientSecret) {
                    getOrderDetails();
                } else {
                    clientSecretRef.current = clientSecret;
                    completePaymentIntent();
                }
            },
            onError: (error: ApiError) => {
                toast.error(error.body.error);
                toggleShowFullScreenLoader(false);
            },
        },
    );

    const { mutate: completePaymentIntent } = useMutation(
        [COMPLETE_PAYMENT_INTENT],
        async () => {
            toggleShowFullScreenLoader(true);

            return getApiInstance().paymentintent.completePaymentIntentPaymentintentCompletePost({
                requestBody: await getPaymentIntentRequestBody(),
                xMp: 'namshi_v2',
            });
        },
        {
            onSuccess: checkPaymentIntentStatus,
            onError: (error: ApiError) => {
                toggleShowFullScreenLoader(false);
                toast.error(error.body.error);
            },
            onSettled: clearCvv,
        },
    );

    const { mutate: getPaymentIntentStatus } = useMutation(
        [GET_PAYMENT_INTENT_STATUS],
        (clientSecret: string) => {
            toggleShowFullScreenLoader(true);

            // 4. Complete payment intent
            return getApiInstance().paymentintent.getTransactionStatusPaymentintentStatusPost({
                requestBody: {
                    clientSecret,
                },
                xMp: 'namshi_v2',
            });
        },
        {
            onSuccess: checkPaymentIntentStatus,
            onError: (error: ApiError) => {
                toggleShowFullScreenLoader(false);
                toast.error(error.body.error);
            },
        },
    );

    return {
        performCheckout,
        checkoutButtonState,
        getPaymentIntentStatus,
    };
};

export default usePerformCheckout;
