import React from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { getValidationSchema } from '@nm-namshi-frontend/core/utils/forms';
import Button from '@nm-namshi-frontend/core/components/Button';
import PasswordInput from '@nm-namshi-frontend/core/components/forms/PasswordInput';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';

import styles from './SignupForm.module.scss';

type TFormValue = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

type TProps = {
    email: string;
    onSubmit: (val: TFormValue) => void;
};

const SignupForm: React.FC<TProps> = (props) => {
    const { email, onSubmit } = props;

    const { t } = useTranslation();
    const formSchemas = getValidationSchema(['firstName', 'lastName', 'email', 'password'], t);

    const formInstance = useFormik<TFormValue>({
        initialValues: {
            email,
            firstName: '',
            lastName: '',
            password: '',
        },
        validateOnMount: true,
        validationSchema: formSchemas,
        onSubmit,
    });

    return (
        <form className={styles.form} onSubmit={formInstance.handleSubmit}>
            <TextInput
                error={
                    formInstance.errors.email && formInstance.touched.email && formInstance.dirty
                        ? formInstance.errors.email
                        : undefined
                }
                label={t('authentication.email-label')}
                name="email"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                onClear={() => formInstance.setFieldValue('email', '', true)}
                value={formInstance.values.email}
                placeholder={t('authentication.email-label')}
                disabled
            />
            <PasswordInput
                error={
                    formInstance.errors.password && formInstance.touched.password && formInstance.dirty
                        ? formInstance.errors.password
                        : undefined
                }
                label={t('authentication.password-label')}
                name="password"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                value={formInstance.values.password}
                placeholder={t('authentication.password-label')}
            />
            <TextInput
                error={
                    formInstance.errors.firstName && formInstance.touched.firstName && formInstance.dirty
                        ? formInstance.errors.firstName
                        : undefined
                }
                label={t('authentication.first-name-label')}
                name="firstName"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                onClear={() => formInstance.setFieldValue('firstName', '', true)}
                value={formInstance.values.firstName}
                placeholder={t('authentication.first-name-label')}
            />

            <TextInput
                error={
                    formInstance.errors.lastName && formInstance.touched.lastName && formInstance.dirty
                        ? formInstance.errors.lastName
                        : undefined
                }
                label={t('authentication.last-name-label')}
                name="lastName"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                onClear={() => formInstance.setFieldValue('lastName', '', true)}
                value={formInstance.values.lastName}
                placeholder={t('authentication.last-name-label')}
            />

            <Button
                variant="tertiary"
                disabled={!(formInstance.isValid && formInstance.dirty)}
                loading={formInstance.isSubmitting}
                type="submit"
                size="large"
            >
                {t('auth.signup.submit-details-cta')}
            </Button>
        </form>
    );
};

export default SignupForm;
