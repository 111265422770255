import clsx from 'clsx';

import styles from './DualTab.module.scss';

type TDualTab<T> = {
    activeTab: T;
    onChange: (val: T) => void;
    tabs: { id: T; label: string }[];
    disabled?: boolean;
};

const DualTab = <T extends string>({ activeTab, disabled = false, onChange, tabs }: TDualTab<T>) => (
    <div className={styles.container}>
        {tabs.map(({ id, label }) => (
            <button key={id} type="button" onClick={() => onChange(id)} className={styles.tab} disabled={disabled}>
                <div className={styles.label}>{label}</div>
            </button>
        ))}

        <div className={clsx(styles.underline, activeTab === tabs[1].id && styles.animate)} />
    </div>
);

export default DualTab;
