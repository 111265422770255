import React from 'react';

import getImages from '@nm-namshi-frontend/core/utils/images';

type TProps = {
    size?: number;
    lang?: 'ar' | 'en';
};

const NoonXNamshiLogo: React.FC<TProps> = ({ lang = 'en', size = 50 }) => {
    const url = lang === 'ar' ? getImages().migration.noonAndNamshiAr : getImages().migration.noonAndNamshiEn;

    return <img style={{ height: size }} src={url} alt="noon and namshi" />;
};

export default NoonXNamshiLogo;
