import React, { useState } from 'react';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import SearchInput from '@nm-namshi-frontend/core/components/SearchInput';
import {
    CountryListingType,
    GetNationalitiesResponse,
} from '@nm-namshi-frontend/services/models/GetNationalitiesResponse';

import styles from './NationalitySelectModal.module.scss';

type TProps = {
    isOpen: boolean;
    onClose: () => void;
    onCountrySelected: (country: CountryListingType) => void;
    listNationalities: GetNationalitiesResponse | undefined;
};

const NationalitySelectModal = ({ isOpen, listNationalities, onClose, onCountrySelected }: TProps) => {
    const { t } = useTranslation();

    const [searchInputValue, setSearchInputValue] = useState('');

    const filterCountriesByName = (filterValue: string, data: any[]) =>
        data.filter((country) => country.name.toLowerCase().includes(filterValue.toLowerCase()));

    const getNationalitiesData = () => {
        if (isEmpty(searchInputValue)) {
            return [
                {
                    title: t('all-nationalities'),
                    data: listNationalities?.countries,
                },
            ];
        }
        return [
            {
                title: t('search-result'),
                data: listNationalities ? filterCountriesByName(searchInputValue, listNationalities.countries) : [],
            },
        ];
    };

    return (
        <Modal
            desktopWidth="50%"
            desktopMaxWidth="900px"
            mobileHeight="50%"
            open={isOpen}
            title={t('add-nationality')}
            onClose={onClose}
        >
            <SearchInput
                placeholder={t('shared.search')}
                value={searchInputValue}
                onChange={setSearchInputValue}
                className={styles.searchInputContainer}
            />

            <div className={styles.dataContainer}>
                {getNationalitiesData().map((nationalityData) => (
                    <div key={nationalityData.title} className={styles.nationalityContainer}>
                        <span className={styles.nationalityTitle}>{nationalityData.title}</span>
                        {nationalityData.data?.map((item: CountryListingType) => (
                            <div
                                className={styles.nationalityItem}
                                key={item.countryCode}
                                onClick={() => {
                                    onCountrySelected(item);
                                    onClose();
                                }}
                            >
                                {item.name}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default NationalitySelectModal;
