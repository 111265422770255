import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAuthenticationStore from '@nm-namshi-frontend/core/stores/useAuthenticationStore';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import { startMigration } from './migrate';

const useStartMigration = () => {
    const { locale } = useAppPathname();
    const { apiRegion } = getLocaleConfig(locale);
    const { setOngoingMigrationHash } = useAuthenticationStore();

    const { t } = useTranslation();

    const doMigrate = () => {
        startMigration({
            countryCode: apiRegion,
            onSuccess: ({ migration_hash }) => {
                setOngoingMigrationHash(migration_hash);
            },
            onError: (err) => {
                const errorText = err.body?.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });

                toast.error(errorText || t('migration.migrate.failure'));
            },
        });
    };

    return doMigrate;
};

export default useStartMigration;
