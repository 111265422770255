import React from 'react';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import Logo from '@nm-namshi-frontend/core/components/Logo';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './LearnMoreButton.module.scss';

type TProps = {
    popupName: 'get_access' | 'mig_intro' | 'mig_learn_more' | 'reset_password_noon' | 'link_more_namshi';
    ctaName: string;
};

const LearnMoreButton: React.FC<TProps> = ({ ctaName, popupName }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const { data } = useQuery(['catalog_learn_more'], () =>
        getApiInstance().page.getPageCms({ pageName: 'one-account-faq' }),
    );

    const content = (data as any)?.cms?.[0]?.modules?.[0]?.data;

    return (
        <>
            <Modal
                title={<Logo localized />}
                mobileHeight="100%"
                open={isOpen}
                onClose={() => setIsOpen(false)}
                desktopMaxWidth="600px"
            >
                <p dangerouslySetInnerHTML={{ __html: content }} />
            </Modal>
            <button
                type="button"
                className={styles.btn}
                onClick={() => {
                    trackEvent({
                        event: 'popup',
                        popupName,
                        popupAction: 'faq',
                    });

                    setIsOpen(true);
                }}
            >
                {ctaName}
            </button>
        </>
    );
};

export default LearnMoreButton;
