import React from 'react';

import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';

import styles from './SizeSelectionSkeleton.module.scss';

const SizeSelectionSkeleton = ({ isMobile }: { isMobile: boolean }) => (
    <div className={styles.skeletonContainer}>
        <div className={styles.navBarSkeleton}>
            {new Array(3).fill(null).map((_, idx) => (
                <Skeleton
                    key={(idx + 1).toString()}
                    height={isMobile ? '100px' : '210px'}
                    width={isMobile ? '100%' : '200px'}
                />
            ))}
        </div>
        <div className={styles.dataSkeletonContainer}>
            {new Array(4).fill(null).map((_, idx) => (
                <div key={(idx + 1).toString()} className={styles.loadingItem}>
                    <Skeleton height="30px" width="50%" />
                    <Skeleton height="30px" width="100%" />
                </div>
            ))}
        </div>
    </div>
);

export default SizeSelectionSkeleton;
