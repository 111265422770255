import React from 'react';

import { clsx } from 'clsx';

import { sanitizeText } from '@nm-namshi-frontend/core/utils/helper';

import styles from './SearchInput.module.scss';
import TextInput from './forms/TextInput';

type TSearchInputProps = {
    value: string;
    onChange: (val: string) => void;
    placeholder?: string;
    className?: string;
    inputClassName?: string;
    onfocus?: () => void;
};

const SearchInput: React.FC<TSearchInputProps> = ({
    className,
    inputClassName,
    onChange,
    onfocus,
    placeholder,
    value,
}) => (
    <TextInput
        displayIcon="search"
        value={value}
        inputClassName={clsx(styles.searchInput, inputClassName)}
        className={className}
        onChange={(e: any) => onChange(sanitizeText(e.target.value))}
        onClear={() => onChange('')}
        placeholder={placeholder}
        type="search"
        onFocus={onfocus}
    />
);

export default SearchInput;
