import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@nm-namshi-frontend/core/components/Button';
import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { ApiError } from '@nm-namshi-frontend/services';
import { OTP_DIGIT_COUNT } from '@nm-namshi-frontend/core/config';

import styles from './VerificationForm.module.scss';
import EmailOrPhoneOTPForm from './EmailOrPhoneOTPForm';

type TPhoneVerificationResponse = {
    data: {
        is_2FA_required: boolean;
        remaining_attempts: number;
    };
};

const { REQUEST_PHONE_VERIFICATION_OTP, VERIFY_PHONE_WITH_OTP } = REACT_QUERY_KEYS;

type TProps = {
    onSuccess: () => void;
    phone: string;
    email: string;
    onBack: () => void;
};

const VerificationForm = ({ email, onBack, onSuccess, phone }: TProps) => {
    // phone code array of length 4, email code array of length 6
    const [phoneCode, setPhoneCode] = useState<Array<string>>(Array(4).fill(''));
    const [emailCode, setEmailCode] = useState<Array<string>>(Array(6).fill(''));
    const [isTwoStepVerification, setIsTwoStepVerification] = useState(true);
    const [resendAttempts, setResendAttempts] = useState<number>(0);
    const [errorText, setErrorText] = useState('');
    const [isResendEnabled, setIsResendEnabled] = useState(false);

    const { t } = useTranslation(['account', 'common']);

    const { mutate: doRequestPhoneVerificationOtp, isError: hasSendOtpFailed, error: requestOTPError } = useMutation(
        [REQUEST_PHONE_VERIFICATION_OTP],
        (phoneNumber: string) =>
            getApiInstance().customer.requestPhoneOtp({
                requestBody: {
                    phone: phoneNumber,
                    is_primary: true,
                },
            }),
        {
            onSuccess: (response) => {
                if (!('data' in response)) return;
                const { data } = response;
                setIsTwoStepVerification(data.is_2FA_required);
                setResendAttempts(data.remaining_attempts);
            },
        },
    );

    const { mutate: doPhoneVerificationWithOtp, isError: hasOtpVerificationFailed, error: verifyOTPError } = useMutation(
        [VERIFY_PHONE_WITH_OTP],
        ({ code, email_Code }: { code: string; email_Code?: string }) =>
            getApiInstance().customer.verifyPhoneOtp({
                requestBody: {
                    code,
                    phone,
                    ...(isTwoStepVerification && { email_code: email_Code }),
                },
            }),
        {
            onSuccess: (data) => {
                setPhoneCode(Array(VERIFICATION_FORM_TEXT.PhoneForm.numOfDigits).fill(''));
                setEmailCode(Array(VERIFICATION_FORM_TEXT.EmailForm.numOfDigits).fill(''));
                const { message } = data as { message: string };
                if (message === 'ok') {
                    onSuccess();
                }
            },
        },
    );

    useEffect(() => {
        if (phone) doRequestPhoneVerificationOtp(phone);
    }, [phone]);

    const VERIFICATION_FORM_TEXT = {
        EmailForm: {
            header: {
                icon: 'email',
                title: t('profile.step-one'),
                subTitle: t('profile.email-verification'),
            },
            numOfDigits: OTP_DIGIT_COUNT.EMAIL,
            content: {
                message: t('profile.sent-email-otp'),
                emailOrPhone: email,
            },
        },
        PhoneForm: {
            header: {
                icon: 'phone',
                title: isTwoStepVerification ? t('profile.step-two') : t('profile.phone-verification'),
                subTitle: isTwoStepVerification ? t('profile.phone-verification') : '',
                changeNumber: true,
            },
            numOfDigits: OTP_DIGIT_COUNT.PHONE,
            content: {
                message: t('profile.sent-phone-otp'),
                emailOrPhone: phone,
            },
        },
    };

    const verifyOTPErrorText = (verifyOTPError as ApiError)?.body.error || '';
    const requestOTPErrorText = (requestOTPError as ApiError)?.body.error || '';

    return (
        <div className={styles.verificationFormContianer}>
            <div className={styles.header}>{t('profile.profile-account_security-verification_prompt')}</div>
            {(verifyOTPErrorText || requestOTPErrorText) && <p className={styles.invalidOTPText}>{verifyOTPErrorText ||  requestOTPErrorText}</p>}
            <div className={styles.stepsContainer}>
                {isTwoStepVerification && (
                    <EmailOrPhoneOTPForm
                        header={VERIFICATION_FORM_TEXT.EmailForm.header}
                        numDigits={VERIFICATION_FORM_TEXT.EmailForm.numOfDigits}
                        content={VERIFICATION_FORM_TEXT.EmailForm.content}
                        code={emailCode}
                        setCode={setEmailCode}
                        isError={hasOtpVerificationFailed}
                    />
                )}
                <EmailOrPhoneOTPForm
                    header={VERIFICATION_FORM_TEXT.PhoneForm.header}
                    numDigits={VERIFICATION_FORM_TEXT.PhoneForm.numOfDigits}
                    content={VERIFICATION_FORM_TEXT.PhoneForm.content}
                    code={phoneCode}
                    setCode={setPhoneCode}
                    onBack={onBack}
                    isDisabled={isTwoStepVerification && !emailCode.every((code) => code)}
                    isError={hasOtpVerificationFailed}
                />
            </div>

            <div className={styles.buttonContainer}>
                <div className={styles.content}>
                    <span className={styles.infoMessage}>
                        {t('profile.didnot-receive-otp')}{' '}
                        {isResendEnabled ? (
                            <button
                                type="button"
                                className={styles.retryMessage}
                                onClick={() => {
                                    doRequestPhoneVerificationOtp(phone);
                                    setIsResendEnabled(false);
                                }}
                                disabled={resendAttempts === 0}
                            >
                                {t('profile.resend-otp')}
                            </button>
                        ) : (
                            <ResendCountDown onComplete={() => setIsResendEnabled(true)} canResendAfter={30} />
                        )}
                    </span>
                    <span className={styles.attempts}>{t('profile.attempts-left', { attempts: resendAttempts })}</span>
                </div>
                <Button
                    variant="tertiary"
                    size="large"
                    onClick={() => {
                        doPhoneVerificationWithOtp({ code: phoneCode.join(''), email_Code: emailCode.join('') });
                    }}
                    disabled={!phoneCode.every((code) => code)}
                >
                    {t('profile.verify')}
                </Button>
            </div>
        </div>
    );
};

const ResendCountDown: React.FC<{ onComplete: () => void; canResendAfter: number }> = ({
    canResendAfter,
    onComplete,
}) => {
    const [timeRemaining, setTimeRemaining] = useState(canResendAfter || 30);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(tick, 1000);

        return () => clearInterval(interval);
    }, []);

    const tick = () => {
        setTimeRemaining((prev) => {
            if (prev <= 0) {
                onComplete();
                return 0;
            }

            return prev - 1;
        });
    };

    return <p>{t('migration.noon-auth.resend-otp-cta-after', { seconds: timeRemaining })}</p>;
};

export default VerificationForm;
