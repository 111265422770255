import { toast } from 'react-toastify';

import {
    PayloadWithAccessTokenAndProvider,
    PayloadWithCodeAndProvider,
    PayloadWithEmailAndPassword,
} from '@nm-namshi-frontend/services';
import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';

const useLegacyLogin = ({ onSuccess }: { onSuccess: () => void }) => {
    const { isLoading, mutate: doLegacyLogin } = useMutation(
        [REACT_QUERY_KEYS.NAMSHI_SIGNIN],
        (payload: PayloadWithAccessTokenAndProvider | PayloadWithCodeAndProvider | PayloadWithEmailAndPassword) =>
            getApiInstance().namshiAuth.namshiSignin({ requestBody: payload }),
        {
            onSuccess,
            onError: (err: any) => {
                toast.error(err.body?.message || err.body.userMessage || err.body?.error);
            },
        },
    );

    return {
        isLoading,
        doSubmit: doLegacyLogin,
    };
};

export default useLegacyLogin;
