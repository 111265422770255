import { create, SetState } from 'zustand';
import { devtools } from 'zustand/middleware';

type TCheckoutStoreType = {
    cvv: string;
    idCustomerToken: string;
    setCvv: (cvv: string) => void;
};

const store = (set: SetState<TCheckoutStoreType>) => ({
    cvv: '',
    idCustomerToken: '',
    setCvv: (cvv: string) => set({ cvv }, false),
    setIdCustomerToken: (idCustomerToken: string) => set({ idCustomerToken }, false),
});

// @TODO Disable devtools on production
const useCheckoutStore = create(devtools(store, { name: 'useCheckoutStore' }));
// const useCheckoutStore = create(store);

export default useCheckoutStore;
