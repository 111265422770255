import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { LanguageCode } from '@nm-namshi-frontend/services/models/LanguageCode';
import { CustomerUpdateRequestV2 } from '@nm-namshi-frontend/services/models/CustomerUpdateRequestV2';
import Radio from '@nm-namshi-frontend/core/components/forms/Radio';
import Select from '@nm-namshi-frontend/core/components/forms/Select';
import Button from '@nm-namshi-frontend/core/components/Button';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';
import { getValidationSchema, TSchemaKeys } from '@nm-namshi-frontend/core/utils/forms';
import {
    CountryListingType,
    GetNationalitiesResponse,
} from '@nm-namshi-frontend/services/models/GetNationalitiesResponse';
import { CustomerDataResponseV2 } from '@nm-namshi-frontend/services/';
import { SignUpSteps } from '@nm-namshi-frontend/core/auth/AuthFlowModal/flows/SignupFlow';
import { LANG_SELECT_OPTIONS } from '@nm-namshi-frontend/core/config';
import Skeleton from '@nm-namshi-frontend/core/components/loaders/Skeleton';
import { trackPage } from '@nm-namshi-frontend/core/utils/analytics';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import NationalitySelectModal from './components/NationalitySelectModal';
import styles from './OnBoardingProfileJourney.module.scss';
import PhoneVerificationFlowModal, {
    PhoneVerificationSteps,
} from './components/PhoneVerificationFlow/PhoneVerificationFlow';

const fields = ['firstName', 'lastName', 'dob', 'preferredLang', 'nationality'];

const OnBoardingProfileJourney = ({
    isJourney = true,
    onSuccess,
    setSignupJourneyStep,
}: {
    setSignupJourneyStep?: (newVal: SignUpSteps) => void;
    isJourney?: boolean;
    onSuccess?: () => void;
}) => {
    const { t } = useTranslation(['account', 'common']);

    const [isNationalityModalOpen, setIsNationalityModalOpen] = useState(false);
    const [countries, setCountries] = useState<CountryListingType[]>([]);
    const [isPhoneModalOpen, setIsPhoneModalopen] = useState(false);
    const rqClient = useQueryClient();
    const { isMobile } = useAppContext();

    const { data: customerData, isLoading: isCustomerDataLoading } = useQuery<CustomerDataResponseV2>(
        [REACT_QUERY_KEYS.GET_V2_CUSTOMER],
        () => getApiInstance().customer.getV2Customer({}),
    );

    useEffect(() => {
        trackPage({
            event: 'page',
            pageType: '/personal/',
        });
    }, []);

    const {
        data: listNationalities,
        isFetched,
        isLoading: isNationalitiesDataLoading,
    } = useQuery<GetNationalitiesResponse>([REACT_QUERY_KEYS.GET_NATIONALITIES], () =>
        getApiInstance().customer.getNationalityList({}),
    );

    useEffect(() => {
        setCountries(listNationalities?.countries ?? []);
    }, [isFetched]);

    const PROFILE_VALIDATION_SCHEMA = useMemo(() => getValidationSchema(fields as Array<TSchemaKeys>, t), [t]);
    const JOURNEY_VALIDATE_SCHEMA = useMemo(() => getValidationSchema(['preferredLang', 'nationality', 'dob'], t), [t]);

    const handleSuccess = () => {
        if (!isJourney) {
            rqClient.invalidateQueries([REACT_QUERY_KEYS.GET_V2_CUSTOMER]);
            rqClient.invalidateQueries([REACT_QUERY_KEYS.GET_CUSTOMER]);
        }
    };

    const profileFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: customerData?.firstName || '',
            lastName: customerData?.lastName || '',
            dob: customerData?.customerProfile.birthDate || '',
            gender: customerData?.customerProfile.gender || null,
            preferredLang: customerData?.languageCode || '',
            nationality: {
                name:
                    countries.find(
                        (country) => country.countryCode === customerData?.customerProfile.nationalityCountryCode,
                    )?.name || '',
                countryCode: customerData?.customerProfile?.nationalityCountryCode || '',
            },
            ...(!isJourney && {
                phone: customerData?.primaryPhoneNumber || '',
            }),
        },
        validateOnMount: true,
        validationSchema: isJourney ? JOURNEY_VALIDATE_SCHEMA : PROFILE_VALIDATION_SCHEMA,
        onSubmit: (values, actions) => {
            const formattedData = {
                ...(isJourney ? {} : { firstName: values.firstName || '', lastName: values?.lastName || '' }),
                languageCode: values.preferredLang as LanguageCode,
                customerProfile: {
                    ...(values.dob && { birthDate: values.dob }),
                    ...(values.gender && { gender: values.gender }),
                    ...(values.nationality?.countryCode && { nationalityCountryCode: values.nationality.countryCode }),
                    ...(!isJourney && values.phone && { phoneNumber: values.phone }),
                },
            };
            updateCustomer(formattedData, {
                onSuccess: () => {
                    toast.success(t('profile._request-success-update-info'));
                    handleSuccess();
                    setSignupJourneyStep?.(SignUpSteps.SIZES);
                },
                onError: (error: any) => {
                    toast.error(error.body.error || t('profile._request-error-update-info'));
                },
                onSettled: () => {
                    actions.setSubmitting(false);
                },
            });
        },
    });

    const renderCustomerNameInputs = () => (
        <div className={styles.rowXs}>
            <div>
                <TextInput
                    error={
                        profileFormik.errors.firstName && profileFormik.touched.firstName
                            ? profileFormik.errors.firstName
                            : undefined
                    }
                    label={t('profile.first-name')}
                    name="firstName"
                    onBlur={profileFormik.handleBlur}
                    onChange={profileFormik.handleChange}
                    value={profileFormik.values.firstName ?? ''}
                    containerClassName={styles.textContainer}
                    loading={isCustomerDataLoading}
                />
            </div>
            <div>
                <TextInput
                    error={
                        profileFormik.errors.lastName && profileFormik.touched.lastName
                            ? profileFormik.errors.lastName
                            : undefined
                    }
                    label={t('profile.last-name')}
                    name="lastName"
                    onBlur={profileFormik.handleBlur}
                    onChange={profileFormik.handleChange}
                    value={profileFormik.values.lastName ?? ''}
                    containerClassName={styles.textContainer}
                    loading={isCustomerDataLoading}
                />
            </div>
        </div>
    );

    const { mutate: updateCustomer } = useMutation(
        [REACT_QUERY_KEYS.UPDATE_CUSTOMER],
        (requestBody: CustomerUpdateRequestV2) =>
            getApiInstance().customer.updateV2Customer({
                requestBody,
            }),
        {
            onSuccess: (updatedCustomerInfo) => {
                if (!isJourney) rqClient.setQueryData([REACT_QUERY_KEYS.GET_CUSTOMER], updatedCustomerInfo);
            },
        },
    );

    const renderLanguageSelector = () => (
        <Select
            id="preferredLangSwitch"
            label={t('profile.preferred-language')}
            onChange={(val) => profileFormik.setFieldValue('preferredLang', val, false)}
            options={LANG_SELECT_OPTIONS}
            value={profileFormik.values.preferredLang}
            customSelectClassName={styles.textContainer}
        />
    );
    const renderDateInput = () => (
        <TextInput
            error={profileFormik.errors.dob && profileFormik.touched.dob ? profileFormik.errors.dob : undefined}
            label={t('profile.date-of-birth')}
            name="dob"
            onBlur={profileFormik.handleBlur}
            onChange={profileFormik.handleChange}
            type="date"
            value={profileFormik.values.dob}
            containerClassName={styles.dobContainer}
            loading={isCustomerDataLoading}
            bottomHelperText={`* ${t('profile.cannot-change')}`}
        />
    );

    const renderGenderRadio = () => (
        <div className={styles.radioGroup}>
            <span className={styles.genderTitle}>{t('profile.gender').toUpperCase()}</span>
            <div>
                <Radio
                    checked={profileFormik.values.gender === 'F'}
                    id="customerGenderFemale"
                    inline
                    name="customerGenderField"
                    onChange={() => profileFormik.setFieldValue('gender', 'F', false)}
                    containerClass={styles.radio}
                >
                    {t('profile.female')}
                </Radio>
                <Radio
                    checked={profileFormik.values.gender === 'M'}
                    id="customerGenderMale"
                    inline
                    name="customerGenderField"
                    onChange={() => profileFormik.setFieldValue('gender', 'M', false)}
                    containerClass={styles.radio}
                >
                    {t('profile.male')}
                </Radio>
            </div>
        </div>
    );

    const renderNationalitySelector = () => (
        <>
            <div onClick={() => setIsNationalityModalOpen(true)}>
                {isNationalitiesDataLoading ? (
                    <Skeleton height={50} />
                ) : (
                    <TextInput
                        placeholder={t('add-nationality')}
                        type="text"
                        value={profileFormik.values.nationality.name ?? ''}
                        onChange={profileFormik.handleChange}
                        label={t('profile.nationality-label')}
                        inputClassName={styles.textContainer}
                        loading={isCustomerDataLoading}
                        disabled={isCustomerDataLoading}
                    />
                )}
            </div>
            <NationalitySelectModal
                isOpen={isNationalityModalOpen}
                onClose={() => setIsNationalityModalOpen(false)}
                onCountrySelected={(val: CountryListingType) => profileFormik.setFieldValue('nationality', val, true)}
                listNationalities={listNationalities}
            />
        </>
    );

    const renderButtons = () => (
        <div className={styles.buttonContainer}>
            <Button
                disabled={profileFormik.isSubmitting || !profileFormik.isValid}
                loading={profileFormik.isSubmitting}
                type="submit"
                variant="tertiary"
                buttonStyles={styles.btn}
                size="large"
            >
                {isJourney ? t('shared.continue-cta').toUpperCase() : t('profile.update-info')}
            </Button>
            {isJourney && (
                <Button variant="secondary" buttonStyles={styles.btn} size="large" onClick={onSuccess}>
                    {t('shared.setup-later')}
                </Button>
            )}
        </div>
    );

    const renderPhoneInput = () => (
        <div
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                setIsPhoneModalopen(true);
                (e.target as HTMLElement)?.blur?.();
            }}
        >
            <TextInput
                error={profileFormik.errors.phone ? profileFormik.errors.phone : undefined}
                label={t('profile.phone-number')}
                name="PhoneNumber"
                onBlur={profileFormik.handleBlur}
                onChange={profileFormik.handleChange}
                value={profileFormik.values.phone ?? ''}
                containerClassName={styles.phoneInputContainer}
                loading={isCustomerDataLoading}
            />
            <PhoneVerificationFlowModal
                isOpen={isPhoneModalOpen}
                customerPhoneNumber={customerData?.primaryPhoneNumber ?? ''}
                onClose={() => setIsPhoneModalopen(false)}
                phoneVerificationStep={PhoneVerificationSteps.changePhoneNumber}
            />
        </div>
    );
    return (
        <form
            onSubmit={profileFormik.handleSubmit}
            className={clsx(styles.container, { [styles.mobile]: isMobile, [styles.isJourney]: isJourney })}
        >
            <div className={clsx({ [styles.boxContainer]: !isJourney, [styles.mobile]: isMobile })}>
                {!isJourney && renderCustomerNameInputs()}
                <div className={styles.formFields}>
                    {renderDateInput()}
                    {isMobile && renderGenderRadio()}
                    {renderLanguageSelector()}
                    {renderNationalitySelector()}
                    {!isJourney && renderPhoneInput()}
                    {!isMobile && renderGenderRadio()}
                </div>
            </div>
            {renderButtons()}
        </form>
    );
};

export default OnBoardingProfileJourney;
