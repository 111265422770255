import { useEffect, useState } from 'react';

import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';

const FB_APP_ID = '456919947786924';

const useFacebookLogin = ({ onSuccess }: { onSuccess: ({ accessToken }: { accessToken: string }) => void }) => {
    const { apiLocale } = getLocaleConfig();
    const [fbResponse, setFbResponse] = useState<any>(null);

    useEffect(() => {
        (window as any).fbAsyncInit = () => {
            (window as any).FB.init({
                appId: FB_APP_ID,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v7.0',
            });

            (window as any).FB.getLoginStatus(setFbResponse);
        };

        ((d, s, id) => {
            const fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            const js: any = d.createElement(s);
            js.id = id;
            js.src = `https://connect.facebook.net/${apiLocale}/sdk.js`;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const loginWithFB = () => {
        if (fbResponse && fbResponse.status === 'connected') {
            onSuccess({
                accessToken: fbResponse.authResponse.accessToken,
            });
        } else {
            (window as any).FB.login(
                (response: any) => {
                    if (response.status === 'connected') {
                        onSuccess({
                            accessToken: response.authResponse.accessToken,
                        });

                        setFbResponse(response);
                    }
                },
                { scope: 'email,user_gender,user_birthday' },
            );
        }
    };

    return loginWithFB;
};

export default useFacebookLogin;
