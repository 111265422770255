import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@nm-namshi-frontend/core/components/Button';
import Modal from '@nm-namshi-frontend/core/components/Modal';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import LegacySocialLogin from '../LegacySocialLogin';
import AuthHeader from '../AuthHeader';
import styles from './LegacySocialLoginButton.module.scss';

type TProps = {
    onSuccess: () => void;
};

const LegacySocialLoginButton: React.FC<TProps> = ({ onSuccess }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const { t } = useTranslation();

    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => {
                    trackEvent({
                        event: 'popup',
                        popupName: 'login_namshi_social',
                        popupAction: 'x',
                    });
                    setIsOpen(false);
                }}
                desktopMaxWidth="400px"
            >
                <div className={styles.container}>
                    <div className={styles.alert}>
                        <Icon name="warningTriangle" size={30} color="gold" />
                        {t('migration.social-alert')}
                    </div>

                    <AuthHeader
                        title={t('migration.login-with-social')}
                        details={t('migration.social-login-to-migrate')}
                    />
                    <LegacySocialLogin onSuccess={onSuccess} />
                </div>
            </Modal>
            <Button
                variant="secondary"
                size="large"
                onClick={() => {
                    trackEvent({
                        event: 'popup',
                        popupName: 'get_access',
                        popupAction: 'login_mig_social',
                    });

                    setIsOpen(true);
                }}
            >
                {t('migration.social-login-cta')}
            </Button>
        </>
    );
};

export default LegacySocialLoginButton;
