import { useEffect, useState } from 'react';

const GOOGLE_CLIENT_ID = '728100608367-mri76feudvrrdg3idkc11lc8608bd81b';

const useGoogleLogin = ({ onSuccess }: { onSuccess: (args: { code: string; provider: 'google' }) => void }) => {
    const [client, setClient] = useState<any>(null);

    useEffect(() => {
        ((d, s, id) => {
            const fjs: any = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            const js: any = d.createElement(s);
            js.id = id;
            js.src = 'https://accounts.google.com/gsi/client';
            js.onload = init;
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'gapi-root');
    }, []);

    const getToken = () => {
        if (!client) {
            init();
        }

        client?.requestCode();
    };

    const init = () => {
        const scope = 'profile email';

        const googleClient = (window.google as any).accounts.oauth2.initCodeClient({
            client_id: GOOGLE_CLIENT_ID,
            scope,
            callback: onSuccess,
        });

        setClient(googleClient);
    };

    return getToken;
};

export default useGoogleLogin;
