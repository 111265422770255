import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { CustomerDataResponseV2 } from '@nm-namshi-frontend/services';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import ChangeNumberFlow from './ChangeNumberFlow';
import styles from './PhoneVerificationFlow.module.scss';
import VerificationForm from './VerificationForm';

type TProps = {
    customerPhoneNumber: string | null;
    isOpen: boolean;
    onClose: () => void;
    phoneVerificationStep: PhoneVerificationSteps;
};
export enum PhoneVerificationSteps {
    changePhoneNumber = 1,
    verificationFlow = 2,
}

const PhoneVerificationFlowModal = ({ customerPhoneNumber, isOpen, onClose, phoneVerificationStep }: TProps) => {
    const { t } = useTranslation('account');
    const queryClient = useQueryClient();

    const [currPhoneVerificationStep, setCurrPhoneVerificationStep] = useState(phoneVerificationStep);
    const [phoneNumber, setPhoneNumber] = useState(customerPhoneNumber || '');

    useEffect(() => {
        if (customerPhoneNumber) setPhoneNumber(customerPhoneNumber);
    }, [customerPhoneNumber]);

    const { data: customerData } = useQuery<CustomerDataResponseV2>([REACT_QUERY_KEYS.GET_V2_CUSTOMER], () =>
        getApiInstance().customer.getV2Customer({}),
    );

    const { email } = customerData || {};

    const handleClose = () => {
        onClose();
        setCurrPhoneVerificationStep(PhoneVerificationSteps.changePhoneNumber);
    };

    const onBack = () => {
        if (currPhoneVerificationStep === PhoneVerificationSteps.changePhoneNumber) {
            onClose();
        } else {
            setCurrPhoneVerificationStep(PhoneVerificationSteps.changePhoneNumber);
        }
    };

    const BackButton = () => (
        <button type="button" onClick={onBack}>
            <Icon name="arrowBack" size={24} />
        </button>
    );
    const title =
        currPhoneVerificationStep === PhoneVerificationSteps.changePhoneNumber
            ? t('profile.phone-number')
            : t('profile.verification-label');

    const customHeader = (
        <header className={styles.header}>
            <BackButton />

            <div className={styles.titleContainer}>
                <h3 className={styles.title}>{title}</h3>
            </div>
        </header>
    );

    return (
        <Modal
            open={isOpen}
            desktopWidth="max-content"
            desktopHeight="auto"
            desktopMaxHeight="90%"
            desktopMaxWidth="auto"
            mobileHeight="100%"
            isContentCovering
            onClose={() => {
                handleClose();
            }}
            containerClass={styles.container}
            customHeader={customHeader}
            showCloseButton={false}
        >
            {currPhoneVerificationStep === PhoneVerificationSteps.changePhoneNumber && (
                <ChangeNumberFlow
                    setCurrPhoneVerificationStep={() =>
                        setCurrPhoneVerificationStep(PhoneVerificationSteps.verificationFlow)
                    }
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    prevPhoneNumber={customerPhoneNumber || ''}
                />
            )}

            {currPhoneVerificationStep === PhoneVerificationSteps.verificationFlow && (
                <VerificationForm
                    onSuccess={() => {
                        toast.success(t('profile.phone-verification-success'));
                        onClose();
                        setCurrPhoneVerificationStep(PhoneVerificationSteps.changePhoneNumber);
                        queryClient.invalidateQueries([REACT_QUERY_KEYS.GET_V2_CUSTOMER]);
                    }}
                    phone={phoneNumber ?? ''}
                    email={email ?? ''}
                    onBack={() => setCurrPhoneVerificationStep(PhoneVerificationSteps.changePhoneNumber)}
                />
            )}
        </Modal>
    );
};

export default PhoneVerificationFlowModal;
