import React from 'react';

import { useTranslation } from 'react-i18next';

import useLegacyLogin from '@nm-namshi-frontend/core/auth/hooks/useLegacyLogin';
import AuthHeader from '@nm-namshi-frontend/core/auth/components/AuthHeader';
import { TFlowProps } from '@nm-namshi-frontend/core/auth/types';
import useLegacyResetPassword from '@nm-namshi-frontend/core/auth/hooks/useLegacyResetPassword';
import PasswordForm from '@nm-namshi-frontend/core/auth/forms/PasswordForm';

const LegacyLoginFlow: React.FC<TFlowProps> = (props) => {
    const { email, onSuccess } = props;

    const { t } = useTranslation();
    const { doSubmit, isLoading } = useLegacyLogin({ onSuccess });
    const { doLegacyResetPassword, isLoading: isResetPasswordLoading } = useLegacyResetPassword();

    return (
        <>
            <AuthHeader title={t('auth.login.title')} details={t('auth.login.legacy-details', { email })} />
            <PasswordForm
                onSubmit={({ password }) => doSubmit({ email, password })}
                onResetPassword={doLegacyResetPassword as any}
                isLoading={isLoading || isResetPasswordLoading}
                ctaText={t('auth.login.title')}
                resetPasswordEmail={email}
            />
        </>
    );
};

export default LegacyLoginFlow;
