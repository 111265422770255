import React from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import AuthHeader from '@nm-namshi-frontend/core/auth/components/AuthHeader';
import EmailForm from '@nm-namshi-frontend/core/auth/forms/EmailForm';
import LearnMoreButton from '@nm-namshi-frontend/core/auth/components/LearnMoreButton';
import { TAuthMethod, TFlowCode } from '@nm-namshi-frontend/core/auth/types';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import getImages from '@nm-namshi-frontend/core/utils/images';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import styles from './FlowStart.module.scss';

type TProps = {
    onSuccess: (val: { flow: TFlowCode; initial_tab?: TAuthMethod; email: string }) => void;
};

const FlowStart: React.FC<TProps> = (props) => {
    const { onSuccess } = props;
    const { t } = useTranslation();
    const { locale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);

    const congratsImage = isArabic ? getImages().migration.congratsAr : getImages().migration.congratsEn;

    const { isLoading, mutate: doInitFlow } = useMutation(
        [REACT_QUERY_KEYS.CHECK_EMAIL],
        (requestBody: { email: string }) => getApiInstance().namshiAuth.initAuthFlow({ requestBody }),
        {
            onError: (err: any) => {
                toast.error(err?.body?.message);
            },
        },
    );

    const handleSubmitEmail = ({ email }: { email: string }) => {
        doInitFlow(
            { email },
            {
                onSuccess: (data: { flow: TFlowCode; initial_tab?: TAuthMethod }) => {
                    onSuccess({ email, ...data });
                },
            },
        );
    };

    return (
        <>
            <div className={styles.welcomeWrapper}>
                <div className={styles.welcomeText}>
                    <h2 className={styles.welcomeTitle}>{t('migration.welcome-to-new-experience')}</h2>
                    <p className={styles.welcomeDetails}>{t('migration.create-and-link-your-account')}</p>
                    <LearnMoreButton ctaName={t('migration.learn-more')} popupName="get_access" />
                </div>

                <img src={congratsImage} className={styles.handImage} />
            </div>
            <div className={styles.container}>
                <AuthHeader title={t('auth.init.title')} details={t('auth.init.details')} />
                <EmailForm
                    ctaText={t('shared.continue-cta')}
                    isLoading={isLoading}
                    onSubmit={({ email }) => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'get_access',
                            popupAction: 'continue',
                        });
                        handleSubmitEmail({ email });
                    }}
                />
            </div>
        </>
    );
};

export default FlowStart;
