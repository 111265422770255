// THIS COMPONENT BASICALLY:
// # takes care of importing currency
// # localization
// # skinny currency weight

import React from 'react';

import { TConversionRates } from '@nm-namshi-frontend/core/types';
import useConfigContext from '@nm-namshi-frontend/core/contexts/ConfigContext';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';

import { convertPrice } from '../utils/helper';

type TProps = {
    className?: string;
    price: number;
    currency?: string; // In case this is API-driven
    showAsNegative?: boolean;
    showAsPositive?: boolean;
    precision?: number;
    conversionRates?: TConversionRates;
};

export default function Price({
    className,
    conversionRates: oldConversionRates,
    currency,
    precision = 2,
    price,
    showAsNegative = false,
    showAsPositive = false,
}: TProps) {
    const { currencyCode: localeCurrency, isArabic } = useAppContext();
    const { conversionRates } = useConfigContext();

    const localPrice = convertPrice({
        conversionRates: oldConversionRates || (conversionRates as TConversionRates),
        fromCurrency: currency,
        toCurrency: localeCurrency,
        price,
    });

    const isNegative = price < 0;

    return (
        <div className={className} dir="ltr">
            <span style={{ fontWeight: 'normal' }} dir={isArabic ? 'rtl' : 'ltr'}>
                {!isArabic && (
                    <>
                        {((!showAsPositive && showAsNegative) || isNegative) && '- '}
                        {!showAsNegative && showAsPositive && !isNegative && '+ '}
                    </>
                )}
                {localeCurrency}
            </span>
            &nbsp;
            <strong style={{ fontVariantNumeric: 'tabular-nums' }}>
                {Math.abs(Number(localPrice)).toFixed(precision)}
            </strong>
            <span style={{ fontWeight: 'normal' }}>
                {isArabic && (
                    <>
                        &nbsp;
                        {((!showAsPositive && showAsNegative) || isNegative) && '- '}
                        {!showAsNegative && showAsPositive && !isNegative && '+ '}
                    </>
                )}
            </span>
        </div>
    );
}
