import React from 'react';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

import { getValidationSchema } from '@nm-namshi-frontend/core/utils/forms';
import Button from '@nm-namshi-frontend/core/components/Button';
import TextInput from '@nm-namshi-frontend/core/components/forms/TextInput';

import styles from './EmailForm.module.scss';

type TFormValue = {
    email: string;
};

type TProps = {
    isLoading: boolean;
    onSubmit: (val: TFormValue) => void;
    ctaText: string;
    disabled?: boolean;
    initialEmail?: string;
};

const EmailForm: React.FC<TProps> = (props) => {
    const { ctaText, disabled = false, initialEmail = '', isLoading, onSubmit } = props;

    const { t } = useTranslation();
    const formSchemas = getValidationSchema(['email'], t);

    const formInstance = useFormik<TFormValue>({
        initialValues: {
            email: '',
        },
        validateOnMount: true,
        validationSchema: formSchemas,
        onSubmit,
    });

    React.useEffect(() => {
        if (initialEmail) {
            formInstance.setFieldValue('email', initialEmail);
        }
    }, [initialEmail]);

    return (
        <form className={styles.form} onSubmit={formInstance.handleSubmit}>
            <TextInput
                error={
                    formInstance.errors.email && formInstance.touched.email && formInstance.dirty
                        ? formInstance.errors.email
                        : undefined
                }
                label={t('authentication.email-label')}
                name="email"
                onBlur={formInstance.handleBlur}
                onChange={formInstance.handleChange}
                onClear={() => formInstance.setFieldValue('email', '', true)}
                value={formInstance.values.email}
                disabled={disabled}
            />

            <Button
                variant="tertiary"
                customStyle={styles.btn}
                disabled={!(formInstance.isValid && formInstance.dirty)}
                loading={isLoading}
                type="submit"
                size="large"
            >
                {ctaText}
            </Button>
        </form>
    );
};

export default EmailForm;
