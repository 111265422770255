import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import AuthHeader from '@nm-namshi-frontend/core/auth/components/AuthHeader';
import useOtpAuth from '@nm-namshi-frontend/core/auth/hooks/useOtpAuth';
import OtpForm from '@nm-namshi-frontend/core/auth/forms/OtpForm';
import SignupForm from '@nm-namshi-frontend/core/auth/forms/SignupForm';
import { TFlowProps } from '@nm-namshi-frontend/core/auth/types';
import { useMutation } from '@tanstack/react-query';
import { CustomerUpdateRequest } from '@nm-namshi-frontend/services';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';
import SignUpJourneySteps from '@nm-namshi-frontend/core/journeys/components/SignUpJourneySteps';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import LearnMoreButton from '@nm-namshi-frontend/core/auth/components/LearnMoreButton';
import NoonXNamshiLogo from '@nm-namshi-frontend/core/components/NoonXNamshiLogo';

import styles from './SignupFlow.module.scss';

export enum SignUpSteps {
    SIGNUPFORM = 0,
    PROFILEINFO = 1,
    SIZES = 2,
    ALLSET = 3,
}

type TSigupFlowProps = TFlowProps & {
    onClose: () => void;
    isFormChangeDisabled: boolean;
    isSignupJourneyEnabled?: boolean;
};

const SignupFlow: React.FC<TSigupFlowProps> = (props) => {
    const {
        disableFormChange,
        email,
        isFormChangeDisabled = false,
        isSignupJourneyEnabled = false,
        onBack,
        onClose,
        onSuccess,
    } = props;
    const { t } = useTranslation();

    const [signupJourneyStep, setSignupJourneyStep] = useState<SignUpSteps>(SignUpSteps.SIGNUPFORM);

    const [isVerified, setIsVerified] = React.useState(false);

    const { doResendOtp, doSendOtp, doVerifyOtp, isLoading, otpConfig } = useOtpAuth({
        onSuccess: () => {
            setIsVerified(true);
            disableFormChange();
        },
    });

    const { mutate: doUpdateCustomer } = useMutation(
        [REACT_QUERY_KEYS.UPDATE_CUSTOMER],
        (requestBody: CustomerUpdateRequest) =>
            getApiInstance().customer.updateCustomer({
                requestBody,
            }),
        {
            onError: (err: any) => {
                const errorText = err?.body?.error || err.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });
            },
            onSuccess: () => {
                trackEvent({
                    event: 'signup',
                });
                if (!isSignupJourneyEnabled) {
                    onSuccess();
                    return;
                }
                setSignupJourneyStep(SignUpSteps.PROFILEINFO);
            },
        },
    );

    React.useEffect(() => {
        if (email) {
            doSendOtp({ email });
        }
    }, []);

    const handleSubmit = (values: { password: string; firstName: string; lastName: string }) => {
        doUpdateCustomer({ ...values, passwordNew: values.password });
    };

    if (!isVerified) {
        return (
            <SignUpFlowWrapper
                isFormChangeDisabled={isFormChangeDisabled}
                onClose={onClose}
                onBack={onBack}
                showNoonNamshiLogo
            >
                <AuthHeader title={t('auth.verify.title')} details={t('auth.verify.details-otp', { email })} />
                <OtpForm
                    onResend={() => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'signup_verify_otp',
                            popupAction: 'resend_otp',
                        });

                        doResendOtp();
                    }}
                    onSubmit={({ otp }) => {
                        trackEvent({
                            event: 'popup',
                            popupName: 'signup_verify_otp',
                            popupAction: 'continue',
                        });

                        doVerifyOtp({ otp });
                    }}
                    isLoading={isLoading}
                    otpConfig={otpConfig}
                    ctaText={t('auth.login.title')}
                />
            </SignUpFlowWrapper>
        );
    }

    const onJourneyBack = () => {
        setSignupJourneyStep((prevStep) => (prevStep ? prevStep - 1 : SignUpSteps.SIGNUPFORM));
    };

    if (signupJourneyStep)
        return (
            <SignUpFlowWrapper
                isFormChangeDisabled={isFormChangeDisabled}
                onClose={onClose}
                onBack={onBack}
                showNoonNamshiLogo={false}
            >
                {signupJourneyStep > 1 && (
                    <button type="button" className={styles.backButton} onClick={onJourneyBack}>
                        <Icon name="arrowBack" size={25} />
                    </button>
                )}
                <SignUpJourneySteps
                    step={signupJourneyStep}
                    setSignupJourneyStep={setSignupJourneyStep}
                    onSuccess={onSuccess}
                />
            </SignUpFlowWrapper>
        );

    return (
        <SignUpFlowWrapper
            isFormChangeDisabled={isFormChangeDisabled}
            onClose={onClose}
            onBack={onBack}
            showNoonNamshiLogo
        >
            <AuthHeader title={t('auth.signup.title')} details={t('auth.signup.details')} />
            <SignupForm
                email={email}
                onSubmit={(args) => {
                    trackEvent({
                        event: 'popup',
                        popupName: 'signup_verify_otp',
                        popupAction: 'signup',
                    });

                    handleSubmit(args);
                }}
            />
        </SignUpFlowWrapper>
    );
};

type TFlowWrapperProps = {
    isFormChangeDisabled: boolean;
    onClose: () => void;
    onBack: () => void;
    children: React.ReactNode;
    showNoonNamshiLogo?: boolean;
};

const SignUpFlowWrapper: React.FC<TFlowWrapperProps> = (props) => {
    const { children, isFormChangeDisabled, onBack, onClose, showNoonNamshiLogo } = props;
    const { t } = useTranslation();
    const { isMobile } = useAppContext();
    const { locale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);

    return (
        <div className={styles.flowWrapper}>
            {!isFormChangeDisabled && (
                <>
                    <button type="button" className={styles.backBtn} onClick={onBack}>
                        <Icon name="arrowBack" size={25} />
                    </button>

                    <button type="button" className={styles.closeBtn} onClick={onClose}>
                        <Icon name="close" size={25} />
                    </button>
                </>
            )}

            {showNoonNamshiLogo && (
                <>
                    <div className={styles.learnMoreWrapper}>
                        <LearnMoreButton ctaName={t('auth.init.learn-more')} popupName="get_access" />
                    </div>
                    <div className={styles.logoWrapper}>
                        <NoonXNamshiLogo size={isMobile ? 50 : 70} lang={isArabic ? 'ar' : 'en'} />
                    </div>
                </>
            )}

            {children}
        </div>
    );
};

export default SignupFlow;
