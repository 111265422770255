import { toast } from 'react-toastify';

import { SigninRequest } from '@nm-namshi-frontend/services';
import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

const usePasswordAuth = ({ onSuccess }: { onSuccess: () => void }) => {
    const { isLoading, mutate: submitPassword } = useMutation(
        [REACT_QUERY_KEYS.SIGNIN],
        (emailParams: SigninRequest) =>
            getApiInstance().auth.signin({
                requestBody: emailParams,
            }),
        {
            onSuccess,
            onError: (err: any) => {
                const errorText = err?.body?.error || err.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });
                toast.error(errorText);
            },
        },
    );

    const doSubmitPassword = ({ email, password }: { email: string; password: string }) => {
        submitPassword(
            { email, password },
            {
                onSuccess: (data) => {
                    if (data && 'data' in data) {
                        trackEvent({
                            event: 'signin',
                            email,
                            firstName: data.data.firstName,
                            lastName: data.data.lastName,
                            phone: data.data.primaryPhoneNumber,
                            login_method: 'password',
                        });
                    }
                },
            },
        );
    };

    return {
        isLoading,
        doSubmitPassword,
    };
};

export default usePasswordAuth;
