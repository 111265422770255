import React from 'react';

import { toast } from 'react-toastify';

import { useMutation } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { trackEvent } from '@nm-namshi-frontend/core/utils/analytics';

import { TOtpConfig } from '../types';

const useOtpAuth = ({ onOtpSent, onSuccess }: { onSuccess: () => void; onOtpSent?: () => void }) => {
    const [isOtpSent, setIsOtpSent] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [otpConfig, setOtpConfig] = React.useState({} as TOtpConfig);

    const { isLoading: isSubmitEmailLoading, mutate: doSubmitEmail } = useMutation(
        [REACT_QUERY_KEYS.NOON_SIGNIN],
        (payload: { emailOrPhone: string }) => getApiInstance().noonAuth.noonSignin({ requestBody: payload }),
        {
            onSuccess: (data: any) => {
                setOtpConfig({
                    coolDownTimer: data.otpCoolDownTimer,
                    otpLength: data.otpLength,
                    otpMsg: data.otpMsg,
                    otpMethodNote: data.otpMethodNote,
                    verifyMethodMsg: data.verifyMethodMsg,
                });
                setIsOtpSent(true);
                onOtpSent?.();
            },
            onError: (err: any) => {
                const errorText = err?.body?.error || err.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });
                toast.error(errorText);
            },
        },
    );

    const { isLoading: isSubmitOtpLoading, mutate: doSubmitOtp } = useMutation(
        [REACT_QUERY_KEYS.NOON_VERIFY_OTP],
        (payload: { emailOrPhone: string; otp: string }) =>
            getApiInstance().noonAuth.noonVerifyOtp({ requestBody: payload }),
        {
            onSuccess: (data) => {
                if (data && 'data' in data) {
                    trackEvent({
                        event: 'signin',
                        email,
                        firstName: data.data.firstName,
                        lastName: data.data.lastName,
                        phone: data.data.primaryPhoneNumber,
                        login_method: 'otp',
                    });
                }
                onSuccess();
            },
            onError: (err: any) => {
                const errorText = err?.body?.error || err.message;

                if (!errorText) return;

                trackEvent({
                    event: 'error',
                    errorText,
                });

                toast.error(errorText);
            },
        },
    );

    const doSendOtp = ({ email: emailOrPhone }: { email: string }) => {
        doSubmitEmail(
            { emailOrPhone },
            {
                onSuccess: () => {
                    setEmail(emailOrPhone);
                },
            },
        );
    };

    const doVerifyOtp = ({ otp }: { otp: string }) => {
        doSubmitOtp({ emailOrPhone: email, otp });
    };

    return {
        isLoading: isSubmitEmailLoading || isSubmitOtpLoading,
        isOtpSent,
        doSendOtp,
        doVerifyOtp,
        otpConfig,
        doResendOtp: () => doSendOtp({ email }),
    };
};

export default useOtpAuth;
