import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import FieldLayout from '@nm-namshi-frontend/core/components/forms/FieldLayout';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './PasswordInput.module.scss';

type TProps = {
    disabled?: boolean;
    error?: string | null;
    helperText?: string;
    label?: string;
    name?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    tabIndex?: number;
    value: string;
    bottomHelperText?: string;
    autofocus?: boolean;
};

const PasswordInput = ({
    autofocus = false,
    bottomHelperText,
    disabled = false,
    error,
    helperText,
    label,
    name = '',
    onBlur,
    onChange,
    placeholder,
    tabIndex,
    value,
}: TProps): JSX.Element => {
    const [inputType, setInputType] = useState('password');

    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && autofocus) {
            ref.current.focus();
        }
    }, [autofocus]);

    const togglePrivacy = () => {
        setInputType(inputType === 'text' ? 'password' : 'text');
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    return (
        <FieldLayout label={label} error={error} helperText={helperText} bottomHelperText={bottomHelperText}>
            <div className={styles.inputContainer}>
                <input
                    ref={ref}
                    className={clsx(styles.input, error && 'error')}
                    disabled={disabled}
                    name={name}
                    onBlur={onBlur}
                    onChange={handleOnChange}
                    placeholder={placeholder}
                    tabIndex={tabIndex}
                    type={inputType}
                    value={value}
                />
                {!disabled && (
                    <button className={styles.privacy} type="button" onClick={togglePrivacy} tabIndex={-1}>
                        <Icon name={inputType === 'text' ? 'eye' : 'eyeSlash'} size={24} />
                    </button>
                )}
            </div>
        </FieldLayout>
    );
};

export default PasswordInput;
