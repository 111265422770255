import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import PhoneInput from '@nm-namshi-frontend/core/components/forms/phoneInput/PhoneInput';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { useQuery } from '@tanstack/react-query';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { PhoneCodesResponse } from '@nm-namshi-frontend/services';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Button from '@nm-namshi-frontend/core/components/Button';
import LoaderLogo from '@nm-namshi-frontend/core/components/loaders/LoaderLogo';

import styles from './ChangeNumberFlow.module.scss';

type TProps = {
    setCurrPhoneVerificationStep: () => void;
    phoneNumber: string;
    setPhoneNumber: (newPhoneNumber: string) => void;
    prevPhoneNumber: string;
};

const ChangeNumberFlow = ({ phoneNumber, prevPhoneNumber, setCurrPhoneVerificationStep, setPhoneNumber }: TProps) => {
    const { locale } = useAppContext();
    const { t } = useTranslation('account');

    // Hooks
    const { data: phoneCountryCodes, isLoading } = useQuery([REACT_QUERY_KEYS.GET_PHONE_CODES], () =>
        getApiInstance().customer.getPhoneCodes({}),
    );

    useEffect(() => {
        if (prevPhoneNumber) {
            setPhoneNumber(prevPhoneNumber);
        }
    }, [prevPhoneNumber]);

    // Variables
    const defaultLocale = phoneCountryCodes?.country_phone_codes?.find((code) =>
        phoneNumber.startsWith(code.dial_code),
    );

    // Callbacks
    const handleClick = () => {
        if (!phoneNumber || phoneNumber.endsWith('-')) {
            toast.error(t('profile.enter-valid-phone-number'));
        } else {
            setCurrPhoneVerificationStep();
        }
    };

    // Renderers
    const renderDetails = () => <div className={styles.details}>{t('profile.enter-mobile-number')}</div>;

    const renderContent = () => (
        <div className={styles.contentContainer}>
            <PhoneInput
                name="phone"
                label={t('profile.phone-number')}
                value={defaultLocale ? phoneNumber : ''}
                meta={phoneCountryCodes as PhoneCodesResponse}
                onChange={setPhoneNumber}
                defaultDialingCode={defaultLocale?.dial_code || getLocaleConfig(locale).dialingCode || '+971'}
            />
        </div>
    );

    if (isLoading) return <LoaderLogo />;

    return (
        <div className={styles.changeNumberFlowContainer}>
            <div className={styles.mainContainer}>
                {renderDetails()}
                {renderContent()}
            </div>

            <div className={styles.buttonContainer}>
                <Button
                    variant="tertiary"
                    size="large"
                    onClick={handleClick}
                    disabled={phoneNumber === prevPhoneNumber}
                >
                    {t('profile.continue')}
                </Button>
            </div>
        </div>
    );
};

export default ChangeNumberFlow;
