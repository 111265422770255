import React from 'react';

import { useTranslation } from 'react-i18next';

import Modal from '@nm-namshi-frontend/core/components/Modal';
import EmailForm from '@nm-namshi-frontend/core/auth/forms/EmailForm';
import NoonXNamshiLogo from '@nm-namshi-frontend/core/components/NoonXNamshiLogo';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useWindowDimensions from '@nm-namshi-frontend/core/hooks/useWindowDimensions';
import { SCREEN_SIZES } from '@nm-namshi-frontend/core/constants/uiConstants';

import styles from './ResetPasswordButton.module.scss';
import LearnMoreButton from '../LearnMoreButton';

type TProps = {
    onSubmit: (val: { email: string }) => Promise<any>;
    isLoading: boolean;
    onOpen?: () => void;
    defaultEmail?: string;
};

const ResetPasswordButton: React.FC<TProps> = ({ defaultEmail, isLoading, onOpen, onSubmit }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(false);

    const { locale } = useAppPathname();
    const { isArabic } = getLocaleConfig(locale);
    const { width: viewportWidth } = useWindowDimensions();

    const isMobile = viewportWidth <= SCREEN_SIZES.screenMinSm;

    React.useEffect(() => {
        if (isOpen) {
            onOpen?.();
        }
    }, [isOpen]);

    return (
        <>
            <Modal open={isOpen} onClose={() => setIsOpen(false)} desktopMaxWidth="469px">
                <div className={styles.container}>
                    <div className={styles.learnMoreWrapper}>
                        <LearnMoreButton ctaName={t('auth.init.learn-more')} popupName="reset_password_noon" />
                    </div>

                    <div className={styles.logoWrapper}>
                        <NoonXNamshiLogo size={isMobile ? 60 : 70} lang={isArabic ? 'ar' : 'en'} />
                    </div>

                    <div className={styles.header}>
                        <h3 className={styles.title}>{t('migration.namshi-login.reset-password.title')}</h3>
                        <p className={styles.details}>{t('migration.namshi-login.reset-password.details')}</p>
                    </div>
                    <EmailForm
                        ctaText={t('migration.namshi-login.reset-password.cta')}
                        isLoading={isLoading}
                        onSubmit={({ email }) => onSubmit({ email }).then(() => setIsOpen(false))}
                        initialEmail={defaultEmail}
                    />
                </div>
            </Modal>

            <button className={styles.btn} type="button" onClick={() => setIsOpen(true)}>
                {t('migration.namshi-login.forgot-password-cta')}
            </button>
        </>
    );
};

export default ResetPasswordButton;
