import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SIZE_SELECTION_NAV_BAR_LINKS } from '@nm-namshi-frontend/core/config';
import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import { ProfileDepartmentCode } from '@nm-namshi-frontend/services/models/ProfileDepartmentCode';

import styles from './SizeSelectionNavBar.module.scss';

type TProps = {
    setSelectedGender: (gender: ProfileDepartmentCode) => void;
    selectedGender: string;
    primaryCategory?: string;
};

const SizeSelectionNavBar = ({ primaryCategory, selectedGender, setSelectedGender }: TProps) => {
    const { isMobile, lang } = useAppContext();
    const { t } = useTranslation();

    SIZE_SELECTION_NAV_BAR_LINKS.sort((a, b) => {
        if (a.code === primaryCategory) return -1;
        if (b.code === primaryCategory) return 1;
        return 0;
    });

    return (
        <nav className={isMobile ? styles.mobileNavBar : styles.desktopNavBar}>
            {SIZE_SELECTION_NAV_BAR_LINKS.map((navLink) => (
                <button
                    type="button"
                    className={clsx(styles.navItem, {
                        [styles.applied]: selectedGender === navLink.code,
                    })}
                    onClick={() => setSelectedGender(navLink.code as ProfileDepartmentCode)}
                    key={navLink.code}
                >
                    <li className={styles.navItemContent}>
                        <div className={styles.navIconWrapper}>
                            {primaryCategory === navLink.code && (
                                <div className={styles.primaryCategory}>{t('primary')}</div>
                            )}
                            <img src={navLink.iconUrl} sizes="110" alt={navLink.code} />
                        </div>
                        <span className={styles.navText}>{navLink.linkText?.[lang]}</span>
                    </li>
                </button>
            ))}
        </nav>
    );
};

export default SizeSelectionNavBar;
