import React, { useEffect, useMemo, useState } from 'react';

import { isUndefined } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import useAppContext from '@nm-namshi-frontend/core/contexts/AppContext';
import Button from '@nm-namshi-frontend/core/components/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { REACT_QUERY_KEYS } from '@nm-namshi-frontend/core/constants/reactQueryKeys';
import { getApiInstance } from '@nm-namshi-frontend/core/api';
import { ProfileSizePreferenceRequest } from '@nm-namshi-frontend/services/models/ProfileSizePreferenceRequest';
import Toggle from '@nm-namshi-frontend/core/components/forms/Toggle';
import { CategorySizePreferenceResponse } from '@nm-namshi-frontend/services/models/CategorySizePreferenceResponse';
import { ProfileDepartmentCode } from '@nm-namshi-frontend/services/models/ProfileDepartmentCode';
import { SizePreference } from '@nm-namshi-frontend/services/models/SizePreference';

import styles from './SizeSelectionDataList.module.scss';

type TProps = {
    sizeDataResponse?: CategorySizePreferenceResponse;
    selectedGender: ProfileDepartmentCode;
    primaryCategory?: ProfileDepartmentCode;
    onSuccess?: () => void;
};

type TSizeRecord = { categoryCode: string; categoryGroupingCode: string; selections: string[] };

const getIsSelectionChanged = (
    selectedSizes: Record<string, Record<string, TSizeRecord>>,
    preferences: Record<string, SizePreference[]> | undefined,
) => {
    if (!preferences) return true;
    let hasChanged = false;

    // if selectedSizes is empty, then it has not changed
    if (Object.keys(selectedSizes).length === 0) {
        return false;
    }

    Object.keys(preferences).forEach((gender) => {
        const currentPreferencesGender = preferences[gender];
        const currentSelectedSizesGender = selectedSizes[gender];

        currentPreferencesGender.forEach((prefrencesCategory) => {
            const compoundKey = `${prefrencesCategory.categoryCode}-${prefrencesCategory.categoryGroupingCode}`;
            const currentSelectedCategory = currentSelectedSizesGender[compoundKey];

            const preferencesSelections = prefrencesCategory.selections || [];
            const selectedSelections = currentSelectedCategory?.selections || [];

            if (preferencesSelections.length !== selectedSelections.length) {
                hasChanged = true;
            }

            // loop over selections and check if they are the same
            preferencesSelections.forEach((selection) => {
                if (!selectedSelections.includes(selection)) {
                    hasChanged = true;
                }
            });
        });
    });

    return hasChanged;
};
const SizeSelectionDataList = ({ onSuccess, primaryCategory, selectedGender, sizeDataResponse }: TProps) => {
    const { t } = useTranslation();
    const { t: tAccount } = useTranslation('account');
    const { isMobile } = useAppContext();
    const rqClient = useQueryClient();

    const [selectedSizes, setSelectedSizes] = useState<Record<string, Record<string, TSizeRecord>>>({});
    const [isToggled, setIsToggled] = useState<boolean>(false);
    // useRef for isUpdatingPrimaryCategory

    const { preferences } = sizeDataResponse || {};
    const sizeData = preferences?.[selectedGender];

    const hasSelectionsChanged = useMemo(
        () => getIsSelectionChanged(selectedSizes, preferences),
        [selectedSizes, preferences],
    );

    useEffect(() => {
        const initialSizes: Record<string, Record<string, TSizeRecord>> = {};

        if (!preferences) return;

        Object.keys(preferences).forEach((gender) => {
            initialSizes[gender] = {};
            preferences[gender].forEach((item) => {
                const flattenedSelections = item.selections?.flat();

                if (flattenedSelections && flattenedSelections.length > 0) {
                    initialSizes[gender][`${item.categoryCode}-${item.categoryGroupingCode}`] = {
                        categoryCode: item.categoryCode,
                        categoryGroupingCode: item.categoryGroupingCode,
                        selections: flattenedSelections || [],
                    };
                }
            });
        });

        setSelectedSizes(initialSizes);
    }, [preferences]);

    const { isLoading, mutate: updateSizeProfile } = useMutation(
        [REACT_QUERY_KEYS.UPDATE_USER_PROFILE_SIZES],
        (requestBody: ProfileSizePreferenceRequest) =>
            getApiInstance().preferences.updateProfileSizePreferences({
                requestBody,
            }),
        {
            onSuccess: () => {
                rqClient.invalidateQueries([REACT_QUERY_KEYS.GET_USER_SIZE_PROFILE_INFO]);
                rqClient.invalidateQueries([REACT_QUERY_KEYS.GET_USER_SIZE_PROFILE_SIZES]);
                setIsToggled(false);

                toast.success(t('sizes-saved'));
            },
        },
    );

    if (isUndefined(sizeDataResponse)) return null;

    const getNewPreferences = () =>
        Object.keys(selectedSizes)
            .map((gender) => {
                const size = selectedSizes[gender];

                return Object.values(size).map((value) => ({
                    categoryCode: value.categoryCode,
                    categoryGroupingCode: value.categoryGroupingCode,
                    selections: value.selections || [],
                    departmentCode: gender as ProfileDepartmentCode,
                }));
            })
            .flat();

    const handleClick = (categoryCode: string, categoryGroupingCode: string, size: string) => {
        setSelectedSizes((prevSizes) => {
            const currentSizes = prevSizes[selectedGender] || {};

            const compoundKey = `${categoryCode}-${categoryGroupingCode}`;

            const doesCategoryExist = compoundKey in currentSizes;

            if (!doesCategoryExist) {
                currentSizes[compoundKey] = {
                    categoryCode,
                    categoryGroupingCode,
                    selections: [],
                };
            }

            if (!currentSizes[compoundKey].selections.includes(size)) {
                currentSizes[compoundKey].selections.push(size);
            } else {
                currentSizes[compoundKey].selections = currentSizes[compoundKey].selections.filter((s) => s !== size);
            }

            return {
                ...prevSizes,
                [selectedGender]: currentSizes,
            };
        });
    };

    const renderSelections = (selected: string[] = [], selections: string[] = []) => {
        const combinedSelections = [...selected, ...selections];
        const uniqueSelections = combinedSelections.filter((item, index) => combinedSelections.indexOf(item) === index);
        const filteredSelections = uniqueSelections.filter(Boolean);

        return (
            <ul className={styles.selectionList}>
                {filteredSelections.map((item) => (
                    <div key={item} className={styles.selectionItem}>
                        {item}
                    </div>
                ))}
            </ul>
        );
    };

    const shouldRenderToggle = primaryCategory !== selectedGender;

    const renderPrimaryCategoryToggle = () =>
        shouldRenderToggle && (
            <Toggle
                containerClass={clsx(styles.toggleContainer, {
                    [styles.mobile]: isMobile,
                })}
                id="primary-category-toggle"
                onChange={() => {
                    setIsToggled(true);
                    updateSizeProfile({
                        primaryCategory: selectedGender,
                    });
                }}
                loading={isLoading}
                checked={isToggled}
                iconPosition="after"
            >
                <span className={styles.toggleText}>{tAccount('profile.make-primary')}</span>
            </Toggle>
        );

    return (
        <div className={styles.container}>
            <div className={styles.sizesContainer}>
                {!isMobile && (
                    <span className={styles.genderLabel}>
                        {t(`departments.${selectedGender}`)}
                        {renderPrimaryCategoryToggle()}
                    </span>
                )}
                {isMobile && renderPrimaryCategoryToggle()}
                {sizeData?.map(({ availableSizes, categoryCode, categoryGroupingCode, name }) => (
                    <div className={styles.sizeDataContainer} key={`${categoryCode}-${categoryGroupingCode}`}>
                        <div className={styles.sizeDataHeader}>
                            <span className={styles.sizeLabel}>{name}</span>
                            <span className={styles.selections}>
                                {renderSelections(
                                    selectedSizes[selectedGender]?.[`${categoryCode}-${categoryGroupingCode}`]
                                        ?.selections || [],
                                )}
                            </span>
                        </div>
                        <ul className={clsx(styles.sizesList, { [styles.mobile]: isMobile })}>
                            {availableSizes.map((size) => (
                                <button
                                    dir="ltr"
                                    type="button"
                                    key={`${categoryCode}-${categoryGroupingCode}-${size}`}
                                    onClick={() => handleClick(categoryCode, categoryGroupingCode, size)}
                                    className={clsx(styles.sizeItem, {
                                        [styles.sizeItemApplied]:
                                            selectedSizes[selectedGender]?.[
                                                `${categoryCode}-${categoryGroupingCode}`
                                            ]?.selections.includes(size),
                                    })}
                                >
                                    <li>{size}</li>
                                </button>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
            <div className={styles.btnContainer}>
                <div className={styles.btnInnerContainer}>
                    <Button
                        variant="secondary"
                        size="large"
                        onClick={() =>
                            setSelectedSizes((prevSize) => ({
                                ...prevSize,
                                [selectedGender]: {},
                            }))
                        }
                    >
                        {t('search.clear-all')}
                    </Button>
                    <Button
                        variant="tertiary"
                        size="large"
                        onClick={() => {
                            updateSizeProfile(
                                {
                                    preferences: getNewPreferences(),
                                    primaryCategory: primaryCategory || selectedGender,
                                },
                                {
                                    onSuccess: () => {
                                        onSuccess?.();
                                    },
                                },
                            );
                        }}
                        loading={isLoading}
                        disabled={!hasSelectionsChanged}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default SizeSelectionDataList;
