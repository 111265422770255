import { useTranslation } from 'react-i18next';

import getImages from '@nm-namshi-frontend/core/utils/images';
import useLegacyLogin from '@nm-namshi-frontend/core/auth/hooks/useLegacyLogin';

import useGoogleLogin from './hooks/useGoogleLogin';
import useFacebookLogin from './hooks/useFacebookLogin';
import styles from './LegacySocialLogin.module.scss';

const LegacySocialLogin: React.FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
    const { t } = useTranslation();

    const { doSubmit, isLoading } = useLegacyLogin({ onSuccess });

    const googleLogin = useGoogleLogin({
        onSuccess: ({ code }) => doSubmit({ code, provider: 'google' }),
    });

    const facebookLogin = useFacebookLogin({
        onSuccess: ({ accessToken }) => doSubmit({ accessToken, provider: 'facebook' }),
    });

    return (
        <div className={styles.container}>
            <button type="button" className={styles.socialIconWrapper} onClick={facebookLogin} disabled={isLoading}>
                {t('migration.namshi-login.social.cta')}{' '}
                <img src={getImages().logos.facebook} width={30} height={15} alt="facebook" />
            </button>

            <button type="button" className={styles.socialIconWrapper} onClick={googleLogin} disabled={isLoading}>
                {t('migration.namshi-login.social.cta')}
                <div className={styles.googleIconWrapper}>
                    <img src={getImages().logos.google} width={30} height={15} alt="google" />
                </div>
            </button>
        </div>
    );
};

export default LegacySocialLogin;
